import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { useGetOnboardingsWithRelationsMultiple } from '@hooks/apiHooks';
import { Box, CircularProgress, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { CandidatePageLayout } from '../components/CandidatePageLayout/CandidatePageLayout';

export const LastPage: VoidFunctionComponent = () => {
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);
  const applicationId = useSelector((state: RootState) => state.oneOnboarding.applicationId);
  const userId = useSelector((state: RootState) => state.authApp.userId);
  const clientOrganizationId = useSelector((state: RootState) => state.oneOnboarding.clientOrganizationId);
  const selectedConfigurationId = useSelector((state: RootState) => state.oneOnboarding.selectedConfigurationId);
  const { data: onboarding, isFetching, isRefetching } = useGetOnboardingsWithRelationsMultiple(
    clientOrganizationId!,
    selectedConfigurationId!,
    userId!,
    applicationId!,
  );
  const { t, i18n } = useTranslation('approval');
  const hasCustomEndMessage = i18n.exists(`approval:actions.customEndMessage.${onboarding?.status}`);
  const isLoading = isFetching && !isRefetching;
  const renderCustomEndMessage = () => {
    if (!hasCustomEndMessage) {
      return (
        <>
          <p>{t('actions.endPage1')}</p>
          <p>{t('actions.endPage2')}</p>
          <p>{t('actions.endPage3')}</p>
        </>
      );
    }
    return <h4>{t(`actions.customEndMessage.${onboarding?.status}`)}</h4>;
  };
  return (
    <CandidatePageLayout hideOverview={true}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        minHeight="80vh"
        sx={{ margin: '16px auto 0 auto' }}
      >
        <Box>{getStepperComponent(themeAdditionalData.stepper, { isLastPage: true })}</Box>
        <Box py={2}>
          <Grid container spacing={3} direction="column">
            <Grid item style={{ textAlign: 'center' }}>
              {isLoading ? <CircularProgress /> : renderCustomEndMessage()}
            </Grid>
          </Grid>
        </Box>
        <Box />
        <Box />
      </Box>
    </CandidatePageLayout>
  );
};
