export enum OnboardingStepGroups {
  REGISTRATION = 'REGISTRATION',
  DOCUMENTS_AND_DATA_UPLOAD = 'DOCUMENTS_AND_DATA_UPLOAD',
  SIGNATURE = 'SIGNATURE',
  PAYMENTS_UPLOAD = 'PAYMENTS_UPLOAD',
  CONTRACTS_UPLOAD = 'CONTRACTS_UPLOAD',
  CANDIDATE_APPROVAL = 'CANDIDATE_APPROVAL',
}

export enum OnboardingStepKeys {
  REGISTRATION_CONSENT = 'REGISTRATION_CONSENT',
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
  REGISTRATION_PERSONAL_INFO = 'REGISTRATION_PERSONAL_INFO',
  DOCUMENTS_AND_DATA_UPLOAD_STARTED = 'DOCUMENTS_AND_DATA_UPLOAD_STARTED',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED',
  CANDIDATE_APPROVAL_COMPLETED = 'CANDIDATE_APPROVAL_COMPLETED',
  SIGNATURE_GETTING_DOCUMENTS = 'SIGNATURE_GETTING_DOCUMENTS',
  SIGNATURE_GET_CONTRACT = 'SIGNATURE_GET_CONTRACT',
  SIGNATURE_RECRUITER = 'SIGNATURE_RECRUITER',
  SIGNATURE_GET_STARTED = 'SIGNATURE_GET_STARTED',
  SIGNATURE_IN_PROGRESS = 'SIGNATURE_IN_PROGRESS',
  SIGNATURE_COMPLETED = 'SIGNATURE_COMPLETED',
  PAYMENTS_UPLOAD_COMPLETED = 'PAYMENTS_UPLOAD_COMPLETED',
  SIGNATURE_SIGN_DOCUMENTS = 'SIGNATURE_SIGN_DOCUMENTS',
  CONTRACTS_UPLOAD_COMPLETED = 'CONTRACTS_UPLOAD_COMPLETED',
}

export enum OnboardingUpdateStepKeys {
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_STUDENT_CARD = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_STUDENT_CARD',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_PASSPORT = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_PASSPORT',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_HAC = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_HAC',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_PHOTO = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_PHOTO',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_WORK_PERMIT = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_WORK_PERMIT',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_SOCIAL_SECURITY_NUMBER_CARD = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_SOCIAL_SECURITY_NUMBER_CARD',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_BANK_ACCOUNT_CERTIFICATE = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_BANK_ACCOUNT_CERTIFICATE',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_DISABILITY_CERTIFICATE = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_DISABILITY_CERTIFICATE',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_LEGAL_CERFA_RESIDENCE_CERTIFICATE = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_LEGAL_CERFA_RESIDENCE_CERTIFICATE',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_RQTH = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_RQTH',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_CASES_1 = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_CASES_1',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_CAR = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_CAR',
  DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_CV = 'DOCUMENTS_AND_DATA_UPLOAD_COMPLETED_CV',
}

export enum OnboardingDocumentStatus {
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  MISSING = 'MISSING',
  VALIDATING = 'VALIDATING',
}
export enum SignDocumentsStatus {
  SIGNED = 'SIGNED',
  MISSING = 'MISSING',
  PREVIEW = 'PREVIEW',
  SEEN = 'SEEN',
  NOT_SEEN = 'NOT_SEEN',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  NOT_ACKNOWLEDGED = 'NOT_ACKNOWLEDGED',
  DOCUMENT_NOT_AVAILABLE = 'DOCUMENT_NOT_AVAILABLE',
  SIGNATURE_MISSING = 'SIGNATURE_MISSING',
}
