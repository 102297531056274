import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { store } from '@store/index';

import { textErrors } from '../../../helpers';
import { editableCondition } from '../../../hooks/useRenderCondition';
import { useT } from '../../../hooks/useT';
import { TextFieldControlModelInterface } from '../../../interfaces';
import { OOFlowWrapper } from '../../../wrappers/FlowWrapper';
import { HtmlCustom } from '../../fields/HtmlCustom';
import { FormControlLoader } from '../../FormControlLoader';

import styles from './TextField.module.scss';
type OOTextFieldProps = {
  controlModel: TextFieldControlModelInterface;
  textFieldProps?: Omit<TextFieldProps, 'value'>;
  onChangeCallback?: (event: any) => void;
  hasHtmlLabel?: boolean;
};

export const OOTextField: React.FC<OOTextFieldProps> = ({
  controlModel,
  textFieldProps,
  onChangeCallback,
  hasHtmlLabel,
}: OOTextFieldProps) => {
  const { t, translationsLoading } = useT('entry', 'approval', 'documents', 'hiring');
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(controlModel.name);
  const fieldMeta = formik.getFieldMeta(controlModel.name);
  const flowData = store.getState().oneOnboarding.flow;
  let prefix = controlModel.label.split('.')[0].toLowerCase();
  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isDisabled = !(
    flowWrapper?.isControlEditable(controlModel.isEditableCandidate, controlModel.isEditableRecruiter) ?? true
  );
  const isEditable = editableCondition(controlModel, flowWrapper, formik.values) ?? true;
  if (controlModel?.defaultValue !== undefined && (fieldProps.value === undefined || fieldProps.value === null)) {
    formik.setFieldValue(controlModel.name, controlModel.defaultValue);
  }
  const onChange = (event: any) => {
    formik.handleChange(event);
    if (onChangeCallback) {
      onChangeCallback(event);
    }
  };
  if (translationsLoading) {
    return <FormControlLoader />;
  }
  return (
    <FormControl className={styles.textFieldContainer}>
      {t(`${prefix}:${controlModel.label}`) && (
        <Box pb={1} sx={{ paddingBottom: '0; !important' }}>
          <FormLabel>
            {!hasHtmlLabel ? (
              `${t(`${prefix}:${controlModel.label}`)}${controlModel.isMandatory ? '*' : ''}`
            ) : (
              <HtmlCustom rawHtml={`${t(`${prefix}:${controlModel.label}`)}${controlModel.isMandatory ? '*' : ''}`} />
            )}
          </FormLabel>
        </Box>
      )}
      <TextField
        {...fieldProps}
        {...textErrors(fieldMeta)}
        {...textFieldProps}
        InputLabelProps={{ required: controlModel.isMandatory, style: { maxWidth: '100%' } }}
        // variant="outlined"
        fullWidth={true}
        placeholder={t(`${prefix}:${controlModel.staticText || controlModel.label}`)}
        onChange={onChange}
        disabled={isDisabled || !isEditable}
        defaultValue={controlModel.defaultValue}
      />
      {controlModel.description && <FormHelperText>{t(`${prefix}:${controlModel.description}`)}</FormHelperText>}
    </FormControl>
  );
};
