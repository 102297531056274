import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';

const CustomeIntegrationStatusMessage = ({ friendlyErrorMessages, setIsOpenCustomIntegrationStatusDialoge }: any) => {
  const { t } = useTranslation(['recruiter']);

  if (!friendlyErrorMessages) return null;

  const msg = friendlyErrorMessages?.reduce(
    (prev: string, curr: string) => `<li style="margin-top:10px">${t(curr)}</li>` + prev,
    '',
  );

  return (
    <>
      <Dialog open={true} onClose={() => setIsOpenCustomIntegrationStatusDialoge(false)}>
        <Box>
          <DialogTitle>
            <p className="tag-ds  h4">{t('recruiter:GENERAL.CANDIDATE_DETAILS.integrationStateErrorMessages')}</p>
          </DialogTitle>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: `<ul>${msg}</ul>` }} />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default CustomeIntegrationStatusMessage;
