import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { Table, TBody, TElement, THead, TRow } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { locales } from '@features/oneOnboarding/helpers';
import { Report } from '@features/oneOnboarding/models/Report';
import { SortingButton } from '@features/oneOnboardingAdmin/components/AdminDashboardTable/SortingButton';
import AdminPagination from '@features/oneOnboardingAdmin/components/AdminPagination';

import { DownloadDocument } from '../../NewCandidateProfilePage/DocumentDetails/DocumentActions/DowloadDocument';

interface ReportTableProps {
  reportList: Report[] | undefined;
  total: number;
  order: string;
  orderBy: string;
  setOrder: (value: string) => void;
  setOrderBy: (value: string) => void;
  pagination: {
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    rowsPerPage: number;
    page: number;
  };
}

const columns = [
  { field: 'name', headerName: 'reportName', sorting: false },
  { field: 'createdAt', headerName: 'date', sorting: true },
  { field: 'url', headerName: 'url', sorting: false },
];

export const ReportTable: React.FC<ReportTableProps> = ({
  reportList,
  total,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  pagination,
}) => {
  const { t } = useTranslation(['recruiter']);
  const aamBackendApi = useInjection(AamBackendApi);
  const selectedLanguage = localStorage.getItem('i18nextLng') || 'en';
  const locale = locales[selectedLanguage];
  const formatTableDate = (date?: any) => (date ? format(parseISO(date), 'MMM dd yyyy', { locale }) : '');

  const dowloadReport = async (name: string, reportId: string) => {
    try {
      const report = await aamBackendApi.downloadReport(reportId);
      if (report.data) {
        const url = window.URL.createObjectURL(new Blob([report.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (err) {
      alert(`Unable to dowload error: ${err.message}`);
    }
  };

  return (
    <div className="tag-ds App">
      <Table>
        <THead>
          <TRow>
            {columns.map(({ field, headerName, sorting }) => (
              <TElement key={field} style={{ postion: 'relative' }} className="feature-report__table-field">
                {sorting && (
                  <SortingButton
                    onClick={() => {
                      setOrder(orderBy === field && order === 'ASC' ? 'DESC' : 'ASC');
                      setOrderBy(field);
                    }}
                    direction={orderBy === field ? order : 'ASC'}
                    active={orderBy === field}
                  />
                )}
                {t(`GENERAL.REPORT.${headerName}`)}
              </TElement>
            ))}
          </TRow>
        </THead>
        <TBody>
          {reportList &&
            reportList?.length > 0 &&
            reportList?.map((item: Report, index: number) => (
              <TRow key={item.id}>
                <TElement>{item.name}</TElement>
                <TElement>{formatTableDate(item.createdAt)}</TElement>
                <TElement>
                  <DownloadDocument handleDocument={async () => await dowloadReport(item.name, item.id)} />
                </TElement>
              </TRow>
            ))}
        </TBody>
      </Table>
      {reportList && reportList.length > 0 && (
        <AdminPagination
          {...pagination}
          total={total}
          currentPage={pagination.page}
          isRowsPerPageVisible={true}
          setPage={pagination.setPage}
        />
      )}
    </div>
  );
};
