import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import * as Yup from 'yup';
import { Header } from '@components/app/Header/Header';
import { useInjection } from '@context/inversify-context-provider';
import { AuthHelper, getRegistrationStepInfo } from '@helpers/authHelper';
import { getTenantCountryCode, getTenantDefaultLanguage } from '@helpers/tenant.helper';
import { useCampaign, useFetchSelectedConfiguration } from '@hooks/apiHooks';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { CircularProgress, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { featureConfigurationSlice } from '@store/slices/featureConfiguration.slice';

import { CustomLogo } from '../controls/CustomLogo';
import { OOFormControl } from '../controls/FormControl';
import { OOControlModel } from '../models/ControlModel';
import { OOPageModel } from '../models/FormModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';

export const Campaign = () => {
  const history = useHistory();
  const formikRef = useRef<any>();
  const aamBackendApi = useInjection(AamBackendApi);
  const location = useLocationQuery();
  const authHelper = useInjection(AuthHelper);
  const tokenApp = useSelector((state: RootState) => state.authApp.idToken);
  const authMethodApp = useSelector((state: RootState) => state.authApp.authMethod);
  const { b2c, appSettings } = useSelector((state: RootState) => state.featureConfiguration);
  const campaignUrl = location.get('campaignUrl');
  const { t } = useTranslation(['entry', 'candidate', 'candidate_recruiter']);
  const [fw, setFw] = useState<OOFlowWrapper | null>(null);
  const [consentPage, setConsentPage] = useState<OOPageModel | null>(null);
  const logo: string = '';

  const { data, isLoading } = useCampaign((campaignUrl as string) || '');
  const selectedLanguage = useMemo(() => {
    if (data?.tenantId) {
      return getTenantDefaultLanguage(data.tenantId);
    } else {
      return 'en';
    }
  }, [data]);

  const fetchFeatureConfigurations = useCallback(async () => {
    await aamBackendApi.fetchFeatureConfigurations({
      dispatch: store.dispatch,
      setStatusAction: featureConfigurationSlice.actions.setStatus,
      setStatusName: 'fetchFeatureConfigurationsStatus',
      dataUpdateAction: featureConfigurationSlice.actions.setFeatureConfigurations,
    });
  }, [aamBackendApi]);

  useEffect(() => {
    if (data?.tenantId) {
      localStorage.setItem('tenantId', data.tenantId.toString());
      fetchFeatureConfigurations();
    }
  }, [data, fetchFeatureConfigurations]);

  const addOrEditUrlParameter = (url: string, parameterName: string, parameterValue: string): string => {
    const queryParamsIndex = url.indexOf('?');
    if (queryParamsIndex === -1) {
      return `${url}?${encodeURIComponent(parameterName)}=${encodeURIComponent(parameterValue)}`;
    }

    const baseUrl = url.substring(0, queryParamsIndex);
    const queryString = url.substring(queryParamsIndex + 1);
    const params = new URLSearchParams(queryString);
    params.set(parameterName, parameterValue);
    const updatedQueryString = params.toString();
    return `${baseUrl}?${updatedQueryString}`;
  };

  const consentPageInitialValues = fw ? consentPage?.getInitialValues(fw) : {};
  const initialValues = { ...consentPageInitialValues };

  const validationSchema = useMemo(() => {
    if (!fw) return null;

    return Yup.object().shape({
      ...consentPage?.getValidationObject(fw, t),
    });
  }, [fw, consentPage, t]);

  const { isInitialLoading: isFlowLoading, refetch } = useFetchSelectedConfiguration(
    data ? data?.clientOrganizationId : '',
    data ? data.selectedConfigurationId : '',
    (data) => {
      const flow = OOFlowWrapper.create(data.flow);
      setFw(flow);
      const [regStepName, regPageName] =
        flow && flow.steps ? getRegistrationStepInfo({ ...flow, flow: flow.steps }) : [];
      const consentStep = flow && flow.steps ? flow.getStep(regStepName) : null;
      const page = consentStep?.pages?.find((itm) => itm.name === regPageName);
      if (page) {
        setConsentPage(page);
      } else {
        b2cSignIn();
      }
    },
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const b2cSignIn = async () => {
    if (data && campaignUrl) {
      let signInUrl = await authHelper.getAuthorizeUrlSignIn(b2c.signIn, history.location, undefined, {
        campaignUrl,
        ui_locales: selectedLanguage,
        country: getTenantCountryCode(data.tenantId),
      });
      localStorage.setItem('campaignUrl', campaignUrl);
      localStorage.setItem('clientOrganizationId', data.clientOrganizationId);
      localStorage.setItem('selectedConfigurationId', data.selectedConfigurationId);
      localStorage.setItem('applicationId', data.applicationId);
      localStorage.setItem('tenantId', data.tenantId.toString());
      signInUrl = addOrEditUrlParameter(signInUrl, 'userjourney', 'walkin');

      authHelper.b2cNavigation(b2c.signIn, signInUrl, history, appSettings.useExternalBrowser);
    }
  };

  const onSubmit = (values: { [p: string]: any }) => {
    const legalConsent: any = {};
    Object.keys(values).forEach((x) => {
      const obj = consentPage?.controls.find((control) => control.name === x);
      if (obj?.sourceField) {
        const key: any = obj.sourceField.split('.')[1];
        legalConsent[key] = values[x];
      }
    });
    localStorage.setItem('campaignConsents', JSON.stringify(legalConsent));
    b2cSignIn();
  };

  if (isLoading || isFlowLoading || consentPage === null) {
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
        }}
      />
    );
  }
  return (
    <>
      {data ? (
        <div className="preRegister">
          <Header
            onBackPress={() => history.go(-1)}
            isAuthenticated={Boolean(tokenApp) && authMethodApp === 'b2c'}
            {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }}
            isOnPreRegisterPage={true}
          />
          <div className="innerContainer">
            <h1 className="primaryHeader">{t('candidate:GENERAL.REGISTRATION.consents')}</h1>
            <div>
              <Formik
                validateOnMount={true}
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit} style={{ display: 'grid', gap: 16 }}>
                    {consentPage?.controls
                      .filter((c) => c.isVisibleCandidate)
                      .map((control: OOControlModel) => (
                        <OOFormControl control={control} key={control.name} />
                      ))}
                    <Button type="submit" variant="contained" color="primary" disabled={!props.isValid}>
                      {t('candidate_recruiter:GENERAL.GENERIC.submit')}
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        <Typography>Wrong url</Typography>
      )}
    </>
  );
};
