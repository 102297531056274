import React from 'react';
import { LanguageSelector } from '@components/app/LanguageSelector/LanguageSelector';
import NotFound from '@components/shared/NotFound/NotFound';
import { AdminAdeccoAdHandler } from '@features/admin/AdminAdeccoAdHandler';
import { OOAsyncImportLine } from '@features/oneOnboardingAdmin/pages/AsyncImportLine';
import { Report } from '@features/oneOnboardingAdmin/pages/Report/Report';
import { TelemetryPage } from '@features/oneOnboardingAdmin/pages/Telemetry/TelemetryPage';
import { UserRole } from '@models/user.model';

import { UserInvitationPage } from '../admin/UserInvitationPage';
import { AppB2CHandler } from '../app/AppB2CHandler';
import { AppLoginPage } from '../app/AppLoginPage';
import { Settings } from '../app/Settings';
import { AccessUnauthorized } from '../oneOnboarding/pages/AccessUnauthorized';
import { ApplicationSelectPage } from '../oneOnboarding/pages/ApplicationSelectPage';
import { Campaign } from '../oneOnboarding/pages/Campaign';
import { NavigationController } from '../oneOnboarding/pages/NavigationController';
import { OneSpanCallbackPage } from '../oneOnboarding/pages/OneSpanCallbackPage';
import { OOPreRegisterPage } from '../oneOnboarding/pages/PreRegisterPage';
import { StartPage } from '../oneOnboarding/pages/StartPage';
import { AdminDashboard } from '../oneOnboardingAdmin/pages/AdminDashboard/AdminDashboard';
import { OOAdminImport } from '../oneOnboardingAdmin/pages/AdminImport/AdminImport';
import AdminImportUsers from '../oneOnboardingAdmin/pages/AdminImportUsers/AdminImportUsers';
import EditCampaign from '../oneOnboardingAdmin/pages/AdminImportUsers/EditCampaign';
import AdminLoginPage from '../oneOnboardingAdmin/pages/AdminLoginPage/AdminLoginPage';
import CampaignDetails from '../oneOnboardingAdmin/pages/CampaignDetails/CampaignDetails';
import CandidateMailOverview from '../oneOnboardingAdmin/pages/CandidateMailOverview/CandidateMailOverview';
import ControlCatalog from '../oneOnboardingAdmin/pages/ControlCatalog/ControlCatalog';
import { CandidateProfilePage } from '../oneOnboardingAdmin/pages/NewCandidateProfilePage/NewCandidateProfilePage';
import TenantSwitcher from '../oneOnboardingAdmin/pages/TenantSwitcher/TenantSwitcher';
import UserPermissionDetails from '../oneOnboardingAdmin/pages/UserPermissions/UserPermissionDetailsPage';
import { UserPermissionsPage } from '../oneOnboardingAdmin/pages/UserPermissions/UserPermissionsPage';
import SelfServiceRouter from '../selfService/SelfServiceRouter';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface RouteRegistration {
  path: string;
  component: React.FC;
  isExact: boolean;
  auth: {
    requiresAdeccoAdToken: boolean;
    requiresB2CToken: boolean;
  };
  allowedRoles?: UserRole[];
}

const defaultAuth = {
  requiresAdeccoAdToken: false,
  requiresB2CToken: false,
};

const createRoutes = (
  prefix: string,
  defaultIsExact: boolean,
  routeParams: Array<PartialBy<RouteRegistration, 'isExact' | 'auth'>>,
): RouteRegistration[] =>
  routeParams.map((routeParam) => ({
    path: prefix + routeParam.path,
    component: routeParam.component,
    isExact: routeParam.isExact ?? defaultIsExact,
    auth: { ...defaultAuth, ...routeParam.auth },
    allowedRoles: routeParam.allowedRoles,
  }));

export const OneOnboardingRoutes: RouteRegistration[] = createRoutes('/oo', true, [
  {
    path: '/',
    component: StartPage,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/flow',
    component: ApplicationSelectPage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: false },
  },
  {
    path: '/settings',
    component: Settings,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/language-select',
    component: LanguageSelector,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/preregister',
    component: OOPreRegisterPage,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/campaign/:campaignUrl?',
    component: Campaign,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/flow/:step?',
    isExact: false,
    component: NavigationController,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: false },
  },
  {
    path: '/onespan-callback',
    component: OneSpanCallbackPage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: false },
  },
  {
    path: '/recruiter/onespan-callback',
    component: OneSpanCallbackPage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/login',
    component: AdminLoginPage,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/recruiter/invites',
    component: UserInvitationPage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    allowedRoles: [UserRole.adeccoAdmin, UserRole.adeccoStaffAndAdmin, UserRole.adeccoConfig],
  },
  {
    path: '/recruiter/control-catalog',
    component: ControlCatalog,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/permissions',
    component: UserPermissionsPage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    allowedRoles: [UserRole.adeccoAdmin, UserRole.adeccoStaffAndAdmin, UserRole.adeccoConfig],
  },
  {
    path: '/recruiter/permissions/:userId',
    component: UserPermissionDetails,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    allowedRoles: [UserRole.adeccoAdmin, UserRole.adeccoStaffAndAdmin, UserRole.adeccoConfig],
  },
  {
    path: '/recruiter/tenant',
    component: TenantSwitcher,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/recruiter',
    component: AdminDashboard,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/report',
    component: Report,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    allowedRoles: [UserRole.adeccoAdmin, UserRole.adeccoStaffAndAdmin, UserRole.adeccoConfig],
  },
  {
    path: '/recruiter/client-organization/:clientOrganizationId/user-flow/:userId/:applicationId/:configurationId',
    component: CandidateProfilePage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path:
      '/recruiter/client-organization/:clientOrganizationId/user-flow/:userId/:applicationId/:configurationId/mail-overview',
    component: CandidateMailOverview,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/async-import',
    component: NotFound,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/import',
    component: OOAdminImport,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    allowedRoles: [UserRole.adeccoConfig],
  },
  {
    path: '/recruiter/import-users/campaigns/:campaignUrl',
    component: CampaignDetails,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/import-users/campaigns/edit/:campaignUrl',
    component: EditCampaign,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/import-users',
    component: AdminImportUsers,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/import-users/:importLineId',
    component: OOAsyncImportLine,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/recruiter/unauthorized',
    component: AccessUnauthorized,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/recruiter/self-service/:page?',
    component: SelfServiceRouter,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    isExact: false,
  },
  {
    path: '/recruiter/telemetry',
    component: TelemetryPage,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
    allowedRoles: [UserRole.adeccoAdmin, UserRole.adeccoStaffAndAdmin, UserRole.adeccoConfig],
  },
  {
    path: '/unauthorized',
    component: AccessUnauthorized,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
]);

OneOnboardingRoutes.push({
  isExact: true,
  path: '/app/login',
  component: StartPage,
  auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
});

OneOnboardingRoutes.push({
  isExact: true,
  path: '/app/aadlogin/token',
  component: AppB2CHandler,
  auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
});

if (process.env.REACT_APP_TELEMETRY_UI_ENABLED === 'true') {
  OneOnboardingRoutes.push(
    createRoutes('/oo', true, [
      {
        path: '/recruiter/telemetry',
        component: TelemetryPage,
        auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
        allowedRoles: [UserRole.adeccoAdmin, UserRole.adeccoStaffAndAdmin, UserRole.adeccoConfig],
      },
    ])[0],
  );
}

export const AppRoutes: RouteRegistration[] = createRoutes('/app', true, [
  {
    path: '/',
    component: NotFound,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: false },
  },
  {
    path: '/login',
    component: AppLoginPage,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/language-select',
    component: NotFound,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/aadlogin/token',
    component: AppB2CHandler,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/metrics',
    component: NotFound,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
]);

export const AdminRoutes: RouteRegistration[] = createRoutes('/admin', true, [
  {
    path: '/docusign-callback',
    component: NotFound,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/onespan-callback',
    component: NotFound,
    auth: { requiresB2CToken: true, requiresAdeccoAdToken: true },
  },
  {
    path: '/aadlogin/token',
    component: AdminAdeccoAdHandler,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
  {
    path: '/metrics',
    component: NotFound,
    auth: { requiresB2CToken: false, requiresAdeccoAdToken: false },
  },
]);

export const ClientRoutes: RouteRegistration[] = createRoutes('/client', true, [
  {
    path: '/aadlogin/token',
    component: NotFound,
  },
]);
