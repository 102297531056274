import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import uk from 'date-fns/locale/uk';
import i18n, { TFunction } from 'i18next';
import * as Yup from 'yup';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { Utils } from '@helpers/utils';

import { renderCondition } from './hooks/useRenderCondition';
import { Component } from './interfaces';
import { OOControlModel } from './models/ControlModel';
import { OODocumentationModel } from './models/DocumentationModel';
import { OOFlowWrapper } from './wrappers/FlowWrapper';

export const selectErrors = (meta: any) => ({
  error: meta && meta.touched && Boolean(meta.error),
});

export const textErrors = (meta: any) => ({
  error: meta && meta.touched && Boolean(meta.error),
  helperText: meta && meta.touched && meta.error,
});

export const checkboxControlError = (meta: any) => ({
  error: meta && meta.touched && Boolean(meta.error),
});
export const checkboxErrorLabel = (meta: any): string | undefined => {
  if (meta && meta.touched && meta.error) {
    if (
      (meta.error as string).endsWith('must be one of the following values: true') ||
      (meta.error as string).endsWith('must have at least 1 items')
    ) {
      return 'required';
    }

    return meta.error as string;
  }

  return undefined;
};

type ValidationFunction = (control: OOControlModel, t: TFunction, prefix?: string, customData?: any) => any;

const getStringTypeValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  let validation: any = Yup.string().nullable();
  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach((i) => {
        const elementToFind = control.parentFormName
          ? `ARRAY_ELEMENT:${control.parentFormName}:${control.parentFormIndex}:${i.control.name}`
          : i.control.name;

        return (validation = validation.when(elementToFind, {
          is: (controlValue: any) => {
            return (
              (typeof i.value !== 'undefined' && i.value === controlValue) ||
              (typeof i.notValue !== 'undefined' && i.notValue !== controlValue)
            );
          },
          then: Yup.string().required(t(`${prefix ? prefix + ':' : ''}validations.required`)),
          otherwise: Yup.string(),
        }));
      });
    } else {
      const validationError = control.mandatoryValidationError ?? 'validations.required';
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`));
    }
  }
  if (control.minLength && typeof control.minLength === 'number') {
    validation = validation.min(control.minLength);
  }
  if (control.maxLength && typeof control.maxLength === 'number') {
    validation = validation.max(control.maxLength);
  }
  let validationMinMaxText = null;
  if (control.min && control.max) {
    if (control.minMaxValidationError) {
      validationMinMaxText = t(`${prefix ? prefix + ':' : ''}${control.minMaxValidationError}`, {
        min: control.min,
        max: control.max,
      });
    }
  }
  if (control.min) {
    validation = validationMinMaxText ? validation.min(control.min, validationMinMaxText) : validation.min(control.min);
  }
  if (control.max) {
    validation = validationMinMaxText ? validation.max(control.max, validationMinMaxText) : validation.max(control.max);
  }
  if (control.regexValidation) {
    if (control.renderCondition) {
      control.renderCondition.forEach((i) => {
        validation = validation.when(i.control.name, {
          is: (controlValue: any) =>
            (typeof i.value !== 'undefined' && i.value === controlValue) ||
            (typeof i.notValue !== 'undefined' && i.notValue !== controlValue),
          then: validation.test(
            'regexValidation',
            t(`${prefix ? prefix + ':' : ''}${control.regExpValidationError}`),
            (value: number) => (value ? `${value}`.match(new RegExp(control.regexValidation || '')) : true),
          ),
          otherwise: Yup.string(),
        });
      });
    } else {
      validation = validation.test(
        'regexValidation',
        t(`${prefix ? prefix + ':' : ''}${control.regExpValidationError}`),
        (value: number) => (value ? `${value}`.match(new RegExp(control.regexValidation || '')) : true),
      );
    }
  }
  return validation;
};

const getNumberTypeValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  let validation: any = Yup.number().typeError(
    control.regExpValidationError ? t(`${prefix}:${control.regExpValidationError}`) : 'Wrong format',
  );
  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach(
        (i) =>
          (validation = validation.when(i.control.name, {
            is: (controlValue: any) =>
              (typeof i.value !== 'undefined' && i.value === controlValue) ||
              (typeof i.notValue !== 'undefined' && i.notValue !== controlValue),
            then: Yup.number().required(t(`${prefix ? prefix + ':' : ''}validations.required`)),
            otherwise: Yup.number(),
          })),
      );
    } else {
      const validationError = control.mandatoryValidationError ?? 'validations.required';
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`));
    }
  } else {
    validation = validation.optional();
  }
  let validationMinMaxText = null;
  if (control.min && control.max) {
    if (control.minMaxValidationError) {
      validationMinMaxText = t(`${prefix ? prefix + ':' : ''}${control.minMaxValidationError}`, {
        min: control.min,
        max: control.max,
      });
    }
  }
  if (control.min) {
    validation = validationMinMaxText ? validation.min(control.min, validationMinMaxText) : validation.min(control.min);
  }
  if (control.max) {
    validation = validationMinMaxText ? validation.max(control.max, validationMinMaxText) : validation.max(control.max);
  }
  if (control.regexValidation) {
    validation = validation.test(
      'regexValidation',
      t(`${prefix ? prefix + ':' : ''}${control.regExpValidationError}`),
      (value: number) => `${value}`.match(new RegExp(control.regexValidation || '')),
    );
  }
  return validation;
};

const getSelectorCheckboxTypeValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  let validation = Yup.boolean().nullable();
  const validationError = control.mandatoryValidationError ?? 'validations.required';
  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach(
        (i) =>
          (validation = validation.when(i.control.name, {
            is: (controlValue: any) =>
              (typeof i.value !== 'undefined' && i.value === controlValue) ||
              (typeof i.notValue !== 'undefined' && i.notValue !== controlValue),
            then: Yup.boolean()
              .required(t(`${prefix ? prefix + ':' : ''}validations.required`))
              .oneOf([true, false]),
            otherwise: Yup.boolean(),
          })),
      );
    } else if (control.component === Component.Consent) {
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`)).oneOf([true]);
    } else {
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`)).oneOf([true, false]);
    }
  }
  return validation;
};

const getSelectorDropdownTypeValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  let validation = Yup.string().nullable();
  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach((i) => {
        let controlToCheckName = i.control.name;

        if (control.parentFormName && (control.parentFormIndex || control.parentFormIndex === 0)) {
          controlToCheckName = OOControlModel.generateNameForSubControl(
            control.parentFormName,
            control.parentFormIndex,
            i.control.name,
          );
        }

        validation = validation.when(controlToCheckName, {
          is: (controlValue: any) =>
            (typeof i.value !== 'undefined' && i.value === controlValue) ||
            (typeof i.notValue !== 'undefined' && i.notValue !== controlValue),
          then: Yup.string().required(t(`${prefix ? prefix + ':' : ''}validations.required`)),
          otherwise: Yup.string(),
        });
      });
    } else {
      const validationError = control.mandatoryValidationError ?? 'validations.required';
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`));
    }
  }
  return validation;
};

const getDateTypeValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  const subContorlPrefix = control.parentFormName
    ? `ARRAY_ELEMENT:${control.parentFormName}:${control.parentFormIndex}:`
    : '';
  let validation = Yup.date()
    .typeError(
      control.minMaxOffsetValidationError ? t(`${prefix}:${control.minMaxOffsetValidationError}`) : 'Invalid date',
    )
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr));
  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach(
        (i) =>
          (validation = validation.when([i.control.name, subContorlPrefix + i.control.name], {
            is: (controlValue: any, subControlValue: any) =>
              (typeof i.value !== 'undefined' && i.value === controlValue) ||
              (typeof i.notValue !== 'undefined' && i.notValue !== controlValue) ||
              (typeof i.value !== 'undefined' && i.value === subControlValue) ||
              (typeof i.notValue !== 'undefined' && i.notValue !== subControlValue),
            then: Yup.date()
              .required(t(`${prefix ? prefix + ':' : ''}validations.required`))
              .typeError(
                control.minMaxOffsetValidationError
                  ? t(`${prefix}:${control.minMaxOffsetValidationError}`)
                  : 'Invalid date',
              ),
            otherwise: Yup.date(),
          })),
      );
    } else {
      const validationError = control.mandatoryValidationError ?? 'validations.required';
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`));
    }

    if (control.minOffset || control.minOffset === 0) {
      const minDate = new Date();
      minDate.setDate(minDate.getDate() + control.minOffset);
      minDate.setHours(0, 0, 0, 0);

      validation = validation.test(
        `${control.name}_INVALID_DATE_RANGE_MIN`,
        t(
          control.minMaxOffsetValidationError
            ? `${prefix}:${control.minMaxOffsetValidationError}`
            : 'minMaxOffsetValidationError',
          { date: minDate.toDateString() },
        ),
        (value: any) => {
          if (value !== undefined && value !== null) {
            const valueDate = new Date(value);
            valueDate.setHours(0, 0, 0, 1);
            return valueDate >= minDate;
          } else {
            return true;
          }
        },
      );
    }

    if (control.maxOffset || control.maxOffset === 0) {
      const maxDate = new Date();
      maxDate.setDate(maxDate.getDate() + control.maxOffset);
      maxDate.setHours(23, 59, 59, 0);

      validation = validation.test(
        `${control.name}_INVALID_DATE_RANGE_MAX`,
        t(
          control.minMaxOffsetValidationError
            ? `${prefix}:${control.minMaxOffsetValidationError}`
            : 'minMaxOffsetValidationError',
          { date: maxDate.toDateString() },
        ),
        (value: any) => {
          if (value !== undefined) {
            const valueDate = new Date(value);
            valueDate.setHours(0, 0, 0, 1);
            return valueDate < maxDate;
          } else {
            return true;
          }
        },
      );
    }
  }
  return validation;
};
const setManualErrorValidation = (
  control: OOControlModel,
  t: TFunction,
  prefix?: string,
  manualErrorMessage?: string,
) => {
  return Yup.string().test('manual', t(`${prefix ? prefix + ':' : ''}${manualErrorMessage}`), () => false);
};

const getMultiSelectValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  let validation = Yup.array().nullable();

  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach(
        (i) =>
          (validation = validation.when(i.control.name, {
            is: (controlValue: any) =>
              (typeof i.value !== 'undefined' && i.value === controlValue) ||
              (typeof i.notValue !== 'undefined' && i.notValue !== controlValue),
            then: Yup.array()
              .min(1)
              .required(t(`${prefix ? prefix + ':' : ''}validations.required`)),
            otherwise: Yup.array(),
          })),
      );
    } else {
      validation = validation.min(1);
      const validationError = control.mandatoryValidationError ?? 'validations.required';
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`));
    }
  }
  return validation;
};

const getSelectorRadioGroupValidation = (control: OOControlModel, t: TFunction, prefix?: string) => {
  let validation = Yup.string().nullable();
  if (control.isMandatory) {
    if (control.renderCondition) {
      control.renderCondition.forEach((i) => {
        const isSubControl = control.name.includes('ARRAY_ELEMENT:');
        const whenOptions = {
          is: (controlValue: any) =>
            (typeof i.value !== 'undefined' && i.value === controlValue) ||
            (typeof i.notValue !== 'undefined' && i.notValue !== controlValue),
          then: Yup.string().required(t(`${prefix ? prefix + ':' : ''}validations.required`)),
          otherwise: Yup.string(),
        };

        if (isSubControl) {
          const controlFromCondition = [...control.name.split(':').slice(0, -1), i.control.name].join(':');
          validation = validation.when(controlFromCondition, whenOptions);
        }
        validation = validation.when(i.control.name, whenOptions);
      });
    } else {
      const validationError = control.mandatoryValidationError ?? 'validations.required';
      validation = validation.required(t(`${prefix ? prefix + ':' : ''}${validationError}`));
    }
  }

  return validation;
};

const validationFunctions: Record<string, ValidationFunction> = {
  string: getStringTypeValidation,
  number: getNumberTypeValidation,
  boolean: getSelectorCheckboxTypeValidation,
  date: getDateTypeValidation,
  select: getSelectorDropdownTypeValidation,
  'multi-select': getMultiSelectValidation,
  radio: getSelectorRadioGroupValidation,
  manual: setManualErrorValidation,
};

const getSelectorGroup: { [key: string]: string } = {
  [Component.CheckboxList]: 'select',
  [Component.Autocomplete]: 'select',
  [Component.AutocompletePreventDefaultOptions]: 'select',
  [Component.SelectWithCheckbox]: 'select',
  [Component.MultipleSelect]: 'select',
  [Component.SelectWithHelpHtml]: 'select',
  [Component.SelectWithInfoHtml]: 'select',
  [Component.SelectWithHelp]: 'select',
  [Component.SelectWithInfo]: 'select',
  [Component.MultiSelectWithCheckbox]: 'select',
  [Component.Select]: 'select',
  [Component.ExtendableAutocomplete]: 'select',
  [Component.SelectWithSequence]: 'select',
  [Component.CheckboxMultiSelect]: 'multi-select',
  [Component.RadioGroup]: 'radio',
  [Component.Ranking]: 'radio',
};

export const controlModelToValidationObject = (
  controls: OOControlModel[],
  t: TFunction,
  isCandidate: boolean,
  flowWrapper: OOFlowWrapper | null,
  prefix?: string,
  formValues?: Record<string, any>,
  initialErrors?: Map<string, any>,
  manualErrors?: Map<string, any>,
): Record<string, any> => {
  if (formValues) {
    controls = controls.filter((c) => renderCondition(c, flowWrapper, formValues));
  }

  const getControlValidation = (control: OOControlModel): ValidationFunction | null => {
    let validation: any;

    const manualErrorMessage = manualErrors?.get(control.name);
    if (manualErrorMessage) {
      return validationFunctions.manual(control, t, prefix, manualErrorMessage);
    }

    if ((control.isVisibleCandidate && isCandidate) || (control.isVisibleRecruiter && !isCandidate)) {
      if (!prefix) {
        prefix = control?.label?.split('.')[0].toLowerCase();
        const prefixAlias: any = {
          documents2: 'documents',
          documents3: 'documents',
        };
        if (prefixAlias[prefix]) {
          prefix = prefixAlias[prefix];
        }
      }
      const validationFunction =
        control.type === 'selector'
          ? validationFunctions[getSelectorGroup[control.component]]
          : validationFunctions[control.type];

      validation = validationFunction ? validationFunction(control, t, prefix) : null;
      if (initialErrors && initialErrors.has(control.name) && validation) {
        validation = validation.test(control.name, '*', (value: any) => value !== initialErrors.get(control.name));
      }
    }

    return validation;
  };

  return controls.reduce((acc, control) => {
    acc[control.name] = getControlValidation(control);

    const subControls = control.subControls as any[];
    if (subControls?.length) {
      subControls.forEach((item) => {
        if (item.length) {
          item.forEach((subControl: any) => {
            acc[subControl.name] = getControlValidation(subControl);
          });
        } else {
          acc[item.name] = getControlValidation(item);
        }
      });
    }
    return acc;
  }, {} as Record<string, any>);
};

export const getDocumentStatus = (
  d: OODocumentationModel,
  hasValidationStep: boolean,
): { status: string; disabled: boolean } => {
  let disabled = false;
  let status: string;
  const uploadedDocuments = d.documents.filter((doc) => (doc.userDocument ? doc : null));
  const findSomeByStatus = (status: OnboardingDocumentStatus) =>
    uploadedDocuments.some((docs) => docs.userDocument.additionalData?.status === status);

  if (uploadedDocuments.length > 0) {
    const missingDocuments = d.documents.every((doc) => (doc.isMandatory && !doc.userDocument ? doc : null));

    if (!missingDocuments) {
      if (hasValidationStep) {
        const rejectedStatus = findSomeByStatus(OnboardingDocumentStatus.REJECTED);
        const approveStatus = findSomeByStatus(OnboardingDocumentStatus.VERIFIED);
        const validateStatus = findSomeByStatus(OnboardingDocumentStatus.VALIDATING);

        if (rejectedStatus) {
          status = OnboardingDocumentStatus.REJECTED;
        } else if (approveStatus) {
          status = OnboardingDocumentStatus.VERIFIED;
        } else if (validateStatus) {
          status = OnboardingDocumentStatus.VALIDATING;
        } else {
          status = OnboardingDocumentStatus.REVIEW;
        }
      } else {
        status = OnboardingDocumentStatus.VERIFIED;
        if (findSomeByStatus(OnboardingDocumentStatus.REVIEW)) {
          status = OnboardingDocumentStatus.REVIEW;
        }
      }
    } else {
      status = OnboardingDocumentStatus.MISSING;
    }
  } else {
    status = OnboardingDocumentStatus.MISSING;
  }

  if (status === OnboardingDocumentStatus.VERIFIED && hasValidationStep) {
    disabled = true;
  }

  return { status, disabled };
};

export const parseValidationErrors = (t: any, err: any) => {
  const errorMessage = err?.response?.data?.error?.data?.message;
  if (!errorMessage) {
    Utils.consoleLog(err?.response?.data);
    return {};
  }

  const errors = JSON.parse(errorMessage);
  const controlStatus = Object.keys(errors).reduce(
    (acc: any, item: any) => ({
      ...acc,
      [item]: i18n.exists(`validations.${item}`, { ns: 'entry' })
        ? t(`validations.${item}`, { ns: 'entry' })
        : i18n.exists(errors[item]?.message || '', { ns: ['documents', 'entry', 'hiring'] })
        ? t(errors[item]?.message || '', { ns: ['documents', 'entry', 'hiring'] })
        : errors[item]?.message || '',
    }),
    {},
  );

  return {
    errors: Object.keys(controlStatus).length > 0 ? controlStatus : null,
  };
};

export const locales: Record<string, Locale> = {
  de,
  en: enUS,
  es,
  fr,
  it,
  uk,
  nl,
};
