import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { DocumentsForSignatureModel, OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { mutateCompleteStep, useGetUserFlow } from '@hooks/apiHooks';
import useBackendTranslations from '@hooks/useBackendTranslations';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { ViewDocument } from '../DocumentDetails/DocumentActions/ViewDocument';
import { ViewSignDocument } from './ViewSignDocument/ViewSignDocument';

import styles from './SignDocuments.module.scss';

interface SignDocumentsProps {
  step: OOStepModel;
  flowWrapper: OOFlowWrapper;
}

enum Signer {
  recruiter = 'recruiter',
  candidate = 'candidate',
}

export const SignDocuments: FC<SignDocumentsProps> = ({ step, flowWrapper }) => {
  const [document, setDocument] = useState<DocumentsForSignatureModel | null>(null);

  const items = step.documentsForSignature;
  const stepAutoComplete = step.stepAutoComplete;
  const { t, i18n } = useTranslation(['hiring', 'candidate_recruiter', 'entry']);
  const { setStep } = useContext(OODocumentContext);

  useBackendTranslations(i18n);

  const isSignedBy = (signer: Signer, doc: DocumentsForSignatureModel) => {
    if (!doc.userDocuments?.length) return false;
    const userDocuments = [...(doc.userDocuments || [])].sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    const document = userDocuments[userDocuments.length - 1];
    return (
      document.type === doc.name &&
      (signer === Signer.recruiter
        ? document.additionalData?.signedByRecruiter === true
        : document.additionalData?.signed === true)
    );
  };

  const isSignedByRecruiter = (doc: DocumentsForSignatureModel) => {
    return isSignedBy(Signer.recruiter, doc);
  };

  const isSignedByCandidate = (doc: DocumentsForSignatureModel) => {
    return isSignedBy(Signer.candidate, doc);
  };
  const isAcknowledgedByCandidate = (doc: DocumentsForSignatureModel) => {
    if (!doc.userDocuments?.length) return { acknowledged: false, acknowledgedAtDate: '' };
    const userDocuments = [...(doc.userDocuments || [])].sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    const document = userDocuments[userDocuments.length - 1];
    const acknowledged = document.type === doc.name && document.additionalData?.acknowledged === true;
    const acknowledgedAtDate = document.type === doc.name && (document.additionalData?.acknowledgedAtDate as string);
    return { acknowledged, acknowledgedAtDate: acknowledgedAtDate };
  };
  const isViewedByCandidate = (doc: DocumentsForSignatureModel) => {
    if (!doc.userDocuments?.length) return { seen: false, seenAtDate: '' };
    const userDocuments = [...(doc.userDocuments || [])].sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    const document = userDocuments[userDocuments.length - 1];
    const seen = document.type === doc.name && document.additionalData?.seen === true;
    const seenAtDate = document.type === doc.name && document.additionalData?.seenAtDate;
    return { seen, seenAtDate };
  };

  const isAwaitingDocument = (doc: DocumentsForSignatureModel) =>
    !doc.userDocuments?.find((d: any) => d.type === doc.name) && doc.needPersonalization;

  const getPrefix = (doc: DocumentsForSignatureModel) => doc?.label?.split('.')[0].toLowerCase() || 'approval';

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();

  const { data: userFlow } = useGetUserFlow(clientOrganizationId, configurationId, userId, applicationId);

  const queryClient = useQueryClient();

  const { mutate: completeStepForUser, isLoading: isCompleting, isError, isSuccess } = mutateCompleteStep(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    OOStepsEnum.recruiterSignDocuments,
    queryClient,
  );

  useEffect(() => {
    setStep(step);
  }, [step, setStep]);

  const documentsForSignature: any = useMemo(() => {
    if (!flowWrapper) {
      return [];
    }
    const stepData: any = flowWrapper.getStep(step.name);
    if (!stepData) {
      return [];
    }
    return stepData.documentsForSignature;
  }, [flowWrapper, step.name]);

  const areAllDocsSignedByRecruiter = useMemo(
    () =>
      documentsForSignature
        .filter((d: any) => d.isRecruiterSignatureRequired)
        .map((d: any) => d?.userDocuments?.find((doc: any) => doc.additionalData.signedByRecruiter === true))
        .every((d: any) => d?.additionalData?.signedByRecruiter === true),
    [documentsForSignature],
  );

  return (
    <div className={styles.signDocuments}>
      <Box style={{ marginBottom: 20 }} display="flex" alignItems="center">
        <span className="material-icons-outlined" style={{ fontSize: 24, marginRight: 16 }}>
          info
        </span>
        <span className="tag-ds  small-title">
          {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.documentShouldBeSignedByRecruiter')}
        </span>
      </Box>
      <div>
        {items.map((doc, i) => {
          const signedByRecruiter = isSignedByRecruiter(doc);
          const signedByCandidate = isSignedByCandidate(doc);
          const awaitingDocument = isAwaitingDocument(doc);
          const { acknowledged } = isAcknowledgedByCandidate(doc);
          const { seen } = isViewedByCandidate(doc);
          const isRecruiterSignatureRequired = doc.isRecruiterSignatureRequired;
          const isCandidateSignatureRequired = doc.isCandidateSignatureRequired;
          const candidateAcknowledgementRequired = doc.candidateAcknowledgementRequired;
          const isSignedVisible = () => {
            if (awaitingDocument) {
              return false;
            }

            if (isRecruiterSignatureRequired && (signedByRecruiter || signedByCandidate)) {
              return true;
            }

            if (isCandidateSignatureRequired && signedByCandidate) {
              return true;
            }

            return false;
          };
          const isReviewedVisible = () => {
            return !isRecruiterSignatureRequired && !isCandidateSignatureRequired;
          };
          const signedTagText = (): string | undefined => {
            if (candidateAcknowledgementRequired) {
              if (!acknowledged) {
                return seen
                  ? t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.seen')
                  : t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.not_seen');
              }
              return t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.acknowledged');
            }
            if (isReviewedVisible()) {
              return t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.reviewed');
            }

            if (isSignedVisible()) {
              return t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.signed');
            }
          };
          const signedTagColor = (): string => {
            if (candidateAcknowledgementRequired) {
              if (!acknowledged) {
                return seen ? 'status-tag_success' : 'status-tag_alert';
              }
              return 'status-tag_success';
            }
            if (isReviewedVisible() || isSignedVisible()) {
              return 'status-tag_success';
            }
            return 'status-tag_warning';
          };
          const prefix = getPrefix(doc);

          return (
            <div key={doc.name + i} className={styles.signDocuments__item}>
              <span className="tag-ds medium" style={{ marginRight: 24 }}>
                {t(`${prefix}:${doc.label}`)}
                {isRecruiterSignatureRequired ? '*' : ''}
              </span>
              <Box display="flex" alignItems="center">
                {signedTagText() && (
                  <div
                    className={`tag-ds ${signedTagColor()}`}
                    style={{ fontSize: 12, marginRight: 24, textTransform: 'uppercase' }}
                  >
                    {signedTagText()}
                  </div>
                )}
                <ViewDocument
                  handleDocument={() => {
                    setDocument(doc);
                    localStorage.setItem('selectedConfigurationId', configurationId || '');
                    localStorage.setItem('clientOrganizationId', clientOrganizationId || '');
                  }}
                  disabled={awaitingDocument}
                />
              </Box>
            </div>
          );
        })}
        {!stepAutoComplete && (
          <Box display="flex" justifyContent="flex-end">
            <div className="tag-ds ">
              <button
                onClick={() => completeStepForUser()}
                disabled={
                  ((isCompleting || isSuccess) && !isError) ||
                  !areAllDocsSignedByRecruiter ||
                  userFlow?.currentStep !== OOStepsEnum.recruiterSignDocuments
                }
              >
                {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.completeStep')}
              </button>
            </div>
          </Box>
        )}
        {document && <ViewSignDocument onClose={() => setDocument(null)} selectedDoc={document} />}
      </div>
    </div>
  );
};
