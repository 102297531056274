import { FormikContextType } from 'formik';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class ForceFormDirty implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.allControls = formControls ?? [];
    this.formik = formik;
  }
  async init(value?: any) {
    this.formik.setFieldValue(this.control.name, `${value ? value.trim() : ''} `);
  }
  onChange = (event: any) => {
    // this class is used to force the form to be dirty as a work around for the AU multiple journeys issue, where the data is populated from the backend and page has jumpCondition.
    // To bypass the following fix for Germany bugfix/121031-avoid-revert-steps
  };
}
