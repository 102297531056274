import React from 'react';
import { OnboardingDocumentStatus, SignDocumentsStatus } from '@helpers/OnboardingSteps';
import {
  AccessTime,
  AccessTimeFilled,
  Cancel,
  CheckCircle,
  Error as ErrorIcon,
  PublishedWithChanges,
  RemoveRedEye,
  SearchOff,
} from '@mui/icons-material';

import { DocumentStatusProps } from './DocumentStatuses';

export const dictDocumentStatuses: DocumentStatusProps[] = [
  {
    Icon: ErrorIcon,
    text: 'status_mandatory',
    color: '#1C304B',
  },
  {
    Icon: SearchOff,
    text: 'status_missing',
    color: '#FFC133',
  },
  {
    Icon: AccessTime,
    text: 'status_review',
    color: '#33CDFF',
  },
  {
    Icon: Cancel,
    text: 'status_rejected',
    color: '#E87A71',
  },
  {
    Icon: CheckCircle,
    text: 'status_verified',
    color: '#73D85A',
  },
  {
    Icon: PublishedWithChanges,
    text: 'validate',
    color: 'black',
  },
];

export const getDocumentStatusIcon = (isMandatory: boolean, status: string) => {
  if (isMandatory && status === OnboardingDocumentStatus.MISSING) {
    return <ErrorIcon fontSize="small" htmlColor="#1C304B" />;
  }
  switch (status) {
    case OnboardingDocumentStatus.VALIDATING:
      return <PublishedWithChanges fontSize="small" htmlColor="#000" />;
    case OnboardingDocumentStatus.MISSING:
      return <SearchOff fontSize="small" htmlColor="#FFC133" />;
    case OnboardingDocumentStatus.REVIEW:
      return <AccessTime fontSize="small" htmlColor="#33CDFF" />;
    case 'PREVIEW':
      return <CheckCircle fontSize="small" htmlColor="#ADADB5" />;
    case OnboardingDocumentStatus.REJECTED:
      return <Cancel fontSize="small" htmlColor="#E87A71" />;
    case OnboardingDocumentStatus.VERIFIED:
      return <CheckCircle fontSize="small" htmlColor="#73D85A" />;
    case 'SIGNED':
      return <CheckCircle fontSize="small" htmlColor="#73D85A" />;
    default:
      return <ErrorIcon fontSize="small" htmlColor="#FFC133" />;
  }
};

export const getDocumentForSignatureStatusIcon = (status: string) => {
  switch (status) {
    case SignDocumentsStatus.DOCUMENT_NOT_AVAILABLE:
      return <ErrorIcon style={{ fontSize: 16 }} htmlColor="#968c83" />;
    case OnboardingDocumentStatus.MISSING:
    case SignDocumentsStatus.NOT_SEEN:
    case SignDocumentsStatus.NOT_ACKNOWLEDGED:
    case SignDocumentsStatus.SIGNATURE_MISSING:
      return <ErrorIcon style={{ fontSize: 16 }} htmlColor="#FFC133" />;
    case OnboardingDocumentStatus.REVIEW:
      return <AccessTimeFilled style={{ fontSize: 16 }} htmlColor="#33CDFF" />;
    case SignDocumentsStatus.PREVIEW:
    case SignDocumentsStatus.SEEN:
      return <RemoveRedEye style={{ fontSize: 16 }} htmlColor="#33CDFF" />;
    case OnboardingDocumentStatus.VERIFIED:
    case SignDocumentsStatus.SIGNED:
    case SignDocumentsStatus.ACKNOWLEDGED:
      return <CheckCircle style={{ fontSize: 16 }} htmlColor="#73D85A" />;
    default:
      return <ErrorIcon style={{ fontSize: 16 }} htmlColor="#FFC133" />;
  }
};
