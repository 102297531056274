import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@adeccoux/tag-ds';
import { Box } from '@mui/material';

interface TableFiltersProps {
  dateFilter: {
    startDate: string | null;
    setStartDate: (date: string) => void;
    endDate: string | null;
    setEndDate: (date: string) => void;
    tzOffset: number;
  };
}

const TableFilters: React.FC<TableFiltersProps> = ({ dateFilter }) => {
  const { t } = useTranslation(['recruiter']);
  const { startDate, setStartDate, endDate, setEndDate, tzOffset } = dateFilter;

  return (
    <div className="tag-ds" style={{ marginBottom: 24 }}>
      <Box display="flex" alignItems="flex-end">
        <div style={{ width: 200, marginRight: 32 }}>
          <DatePicker
            name="datepicker"
            onChange={(date) => {
              const sDate = date ? moment(date).startOf('day').add(-tzOffset, 'seconds').toISOString() : '';

              setStartDate(sDate);
            }}
            label={t('GENERAL.REPORT.headerStartDate')}
            maxDate={new Date().getTime()}
            dateFormat="dd/MM/yyyy"
            defaultValue={moment(startDate).subtract(-tzOffset, 'seconds').toDate().getTime()}
            value={startDate ? moment(startDate).startOf('day').add(-tzOffset, 'seconds').toDate().getTime() : null}
          />
        </div>
        <div style={{ width: 200, marginRight: 32 }}>
          <DatePicker
            name="datepicker"
            onChange={(date) => {
              const eDate = date ? moment(date).endOf('day').add(-tzOffset, 'seconds').toISOString() : '';

              setEndDate(eDate);
            }}
            label={t('GENERAL.REPORT.headerEndDate')}
            maxDate={new Date().getTime()}
            dateFormat="dd/MM/yyyy"
            defaultValue={moment(endDate).subtract(-tzOffset, 'seconds').toDate().getTime()}
            value={endDate ? moment(endDate).endOf('day').add(-tzOffset, 'seconds').toDate().getTime() : null}
          />
        </div>
      </Box>
    </div>
  );
};

export default TableFilters;
