import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import OOTableContainer from '@components/admin/Table/OOTableContainer';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useFetchReports } from '@hooks/apiHooks';
import { CircularProgress, Grid } from '@mui/material';

import { ReportTable } from './components/ReportTable';
import ReportTableFilters from './components/ReportTableFilters';

export const Report: React.FC = () => {
  const { t } = useTranslation(['recruiter', 'control-items']);
  const currentDate = Date.now();
  const tzOffset = new Date().getTimezoneOffset() * 60;
  const initialStartDate = useMemo(() => {
    return moment(currentDate).startOf('day').subtract(10, 'days').add(-tzOffset, 'seconds').toISOString();
  }, [currentDate, tzOffset]);
  const initialEndDate = useMemo(() => {
    return moment(currentDate).endOf('day').add(-tzOffset, 'seconds').toISOString();
  }, [currentDate, tzOffset]);
  const [startDateField, setStartDateField] = useState<string | null>(initialStartDate);
  const [endDateField, setEndDateField] = useState<string | null>(initialEndDate);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = useState<string>('DESC');
  const [orderBy, setOrderBy] = useState<string>('createdAt');

  const { data: responseReportData, isFetching } = useFetchReports({
    page,
    rowsPerPage: rowsPerPage,
    startDate: startDateField,
    endDate: endDateField,
    order: {
      direction: order,
      field: orderBy,
    },
  });

  const [results, total] = useMemo(() => {
    if (responseReportData) {
      return [responseReportData.results, responseReportData.total];
    }
    return [[], 0];
  }, [responseReportData]);

  return (
    <div className="admin">
      <PageWrapper
        featureName="report"
        pageName="report"
        sideMenu
        headerConfig={{ pageName: t('recruiter:GENERAL.REPORT.reportPageTitle'), languagePicker: true }}
      >
        <Grid container className="feature-report__container">
          <Grid item flex={1} style={{ overflow: 'scroll' }} className="tag-ds">
            <OOTableContainer>
              <ReportTableFilters
                dateFilter={{
                  startDate: startDateField,
                  setStartDate: (date) => {
                    setPage(0);
                    setStartDateField(date);
                  },
                  endDate: endDateField,
                  setEndDate: (date) => {
                    setPage(0);
                    setEndDateField(date);
                  },
                  tzOffset,
                }}
              />
              {isFetching ? (
                <div className="feature-report__loader">
                  <CircularProgress />
                </div>
              ) : (
                <ReportTable
                  reportList={results}
                  total={total}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  pagination={{ setRowsPerPage, setPage, rowsPerPage, page }}
                />
              )}
            </OOTableContainer>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
};
