import { OODocuments } from '../interfaces';
import { OOControlModel } from './ControlModel';
import { OODocumentPartModel } from './DocumentPartModel';
import { OOUserDocumentModel } from './UserDocumentModel';

interface ConstructorParams {
  name: OODocuments;
  header: string;
  label: string;
  description: string;
  isMandatory: boolean;
  controls: OOControlModel[];
  parts: OODocumentPartModel[];
  userDocument: OOUserDocumentModel;
  isDisable?: boolean;
  reUpload?: boolean;
}

export class OODocumentModel {
  public name: OODocuments;
  public header: string;
  public label: string;
  public description: string;
  public isMandatory: boolean;
  public controls: OOControlModel[];
  public parts: OODocumentPartModel[];
  public userDocument: OOUserDocumentModel;
  isDisable?: boolean;
  reUpload?: boolean;

  constructor(obj: ConstructorParams) {
    const { name, header, label, description, isMandatory, controls, parts, userDocument, isDisable, reUpload } = obj;
    this.name = name;
    this.header = header;
    this.label = label;
    this.description = description;
    this.isMandatory = isMandatory;
    this.controls = controls;
    this.parts = parts;
    this.userDocument = userDocument;
    this.isDisable = isDisable;
    this.reUpload = reUpload;
  }

  public static fromJson(json: any) {
    const { name, header, label, description, isMandatory, isDisable, reUpload } = json;
    const controls = (json.controls || []).map(OOControlModel.fromJson);
    const parts = (json.parts || []).map(OODocumentPartModel.fromJson);
    const userDocument = json.userDocument;
    return new OODocumentModel({
      name,
      header,
      label,
      description,
      isMandatory,
      controls,
      parts,
      userDocument,
      isDisable,
      reUpload,
    });
  }
}
