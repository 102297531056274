import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useInjection } from '@context/inversify-context-provider';
import { Utils } from '@helpers/utils';
import { mutateSignDocument } from '@hooks/apiHooks';
import { useTenant } from '@hooks/useComponentMapping';
import { useDecodedParams } from '@hooks/useDecodedParams';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';
import AdminHeader from '../../../../components/AdminHeader/AdminHeader';

import styles from './ViewSignDocument.module.scss';
export interface ViewSignDocumentProps {
  onClose: () => void;
  selectedDoc: any;
}

export const ViewSignDocument: FC<ViewSignDocumentProps> = ({ onClose, selectedDoc }) => {
  const [doc, setDoc] = useState(null);
  const [documentPdf, setDocumentPdf] = useState('');
  const [isDocumentSigned, setIsDocumentSigned] = useState(false);
  const [isDocumentAcknowledged, setIsDocumentAcknowledged] = useState({
    acknowledged: false,
    acknowledgedAtDate: '',
  });
  const [isDocumentViewedByCandidate, setIsDocumentViewedByCandidate] = useState({
    seen: false,
    seenAtDate: '',
  });
  const [isDocumentSignedByRecruiter, setIsDocumentSignedByRecruiter] = useState(false);

  const { t, i18n } = useTranslation(['recruiter', 'hiring', 'candidate_recruiter']);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useTenant();
  const { search } = useLocation();
  const filterValue = new URLSearchParams(search).get('filter');

  const handleDownloadDocument = async () => {
    if (selectedDoc) {
      const userDocuments = [...(selectedDoc?.userDocuments || [])].sort(
        (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      const userDocument = selectedDoc?.userDocuments[0];
      const documents = await aamBackendApi.downloadDocument(
        tenantId,
        userId,
        userDocuments[userDocuments.length - 1].id,
      );
      const url = window.URL.createObjectURL(new Blob([documents], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.download = userDocument.name;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const downloadDocument = useCallback(
    async (item: any) => {
      if (selectedDoc) {
        const userDocuments = [...(item.userDocuments || [])].sort(
          (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
        let document;

        if (userId && userDocuments && userDocuments.length > 0) {
          setDoc({ ...selectedDoc, id: userDocuments[userDocuments.length - 1].id });
          document = await aamBackendApi.downloadDocument(tenantId, userId, userDocuments[userDocuments.length - 1].id);
        } else {
          setDoc(selectedDoc);
          document = await aamBackendApi.downloadOODocumentForSignature(item.name);
        }

        const pdf = URL.createObjectURL(
          new Blob([document], {
            type: 'application/pdf',
          }),
        );

        setDocumentPdf(pdf);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aamBackendApi, userId, tenantId, selectedDoc],
  );
  useEffect(() => {
    if (selectedDoc) {
      let isDocumentSignedByRecruiter = false;
      let isDocumentSignedByCandidate = false;
      const userDocuments = [...(selectedDoc.userDocuments || [])].sort(
        (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      const document = userDocuments[userDocuments.length - 1];
      if (document) {
        if (document.type === selectedDoc.name && document.additionalData.signedByRecruiter === true) {
          isDocumentSignedByRecruiter = true;
        }
        const someDocumentSignedByCandidate = selectedDoc.userDocuments?.some(
          (d: any) => d.type === selectedDoc.name && d.additionalData.signed === true,
        );
        if (
          someDocumentSignedByCandidate &&
          document.type === selectedDoc.name &&
          document.additionalData.signed === true
        ) {
          isDocumentSignedByCandidate = true;
          isDocumentSignedByRecruiter = true;
        }
        if (selectedDoc.candidateAcknowledgementRequired) {
          const seen = document.type === selectedDoc.name && document.additionalData.seen === true;
          const seenAtDate = document.type === selectedDoc.name && document.additionalData.seenAtDate;
          setIsDocumentViewedByCandidate({
            seen,
            seenAtDate,
          });
          const acknowledged = document.type === selectedDoc.name && document.additionalData.acknowledged === true;
          const acknowledgedAtDate = document.type === selectedDoc.name && document.additionalData.acknowledgedAtDate;
          setIsDocumentAcknowledged({
            acknowledged,
            acknowledgedAtDate,
          });
        }
        setIsDocumentSigned(isDocumentSignedByCandidate);
        setIsDocumentSignedByRecruiter(isDocumentSignedByRecruiter);
        downloadDocument(selectedDoc);
      }
    }
  }, [selectedDoc, downloadDocument]);

  const { mutate: signDocument, isLoading, isSuccess, isError } = mutateSignDocument(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    i18n.language,
  );

  return (
    <div className={styles.pageContainer}>
      {selectedDoc && (
        <>
          <AdminHeader
            onBack={() => {
              onClose();
            }}
            pageName={t(`${selectedDoc?.label.split('.')[0].toLowerCase() || 'recruiter'}:${selectedDoc.label}`)}
            customStyle={{ paddingLeft: 32 }}
          />
          <div className={styles.contentContainer}>
            <div className={styles.preview}>
              {documentPdf && (
                <object
                  data={documentPdf}
                  type="application/pdf"
                  width="100%"
                  style={{ height: '100%' }}
                  aria-label="Document PDF"
                />
              )}
            </div>
            {(selectedDoc.isRecruiterSignatureRequired ||
              selectedDoc.isCandidateSignatureRequired ||
              selectedDoc.candidateAcknowledgementRequired) && (
              <div className={styles.details}>
                {selectedDoc.isRecruiterSignatureRequired && (
                  <div>
                    <p className="tag-ds subtitle" style={{ marginBottom: 12, marginTop: 0 }}>
                      {t('candidate_recruiter:APPROVAL.SIGNATURE.signatureByRecruiter')}:
                    </p>
                    {isDocumentSignedByRecruiter ? (
                      <div className="tag-ds status-tag_success" style={{ textTransform: 'uppercase' }}>
                        <span className="material-icons">check_circle</span>
                        {t('candidate_recruiter:APPROVAL.SIGNATURE.signed')}
                      </div>
                    ) : (
                      <div className="tag-ds ">
                        <button
                          onClick={() => {
                            const payload: { document: any; filterName?: string } = { document: doc };

                            if (filterValue) {
                              payload.filterName = filterValue;
                            }

                            signDocument(payload);
                          }}
                          disabled={isLoading || (isSuccess && !isError)}
                        >
                          {t('candidate_recruiter:APPROVAL.SIGNATURE.sign')}
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  {selectedDoc?.showDownloadButton && (
                    <div className="tag-ds" style={{ marginBottom: 32 }}>
                      <button className="button-secondary" onClick={handleDownloadDocument}>
                        <span className="material-icons">download</span>
                        {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.download')}
                      </button>
                    </div>
                  )}
                  {selectedDoc.isCandidateSignatureRequired && (
                    <div>
                      <p className="tag-ds subtitle" style={{ marginBottom: 8, marginTop: 0 }}>
                        {t('candidate_recruiter:APPROVAL.SIGNATURE.signatureByCandidate')}:
                      </p>

                      {isDocumentSigned ? (
                        <div className="tag-ds status-tag_success" style={{ textTransform: 'uppercase' }}>
                          <span className="material-icons">check_circle</span>
                          {t('candidate_recruiter:APPROVAL.SIGNATURE.signed')}
                        </div>
                      ) : (
                        <div className="tag-ds status-tag_alert" style={{ textTransform: 'uppercase' }}>
                          {t('candidate_recruiter:APPROVAL.SIGNATURE.missing')}
                        </div>
                      )}
                    </div>
                  )}
                  {selectedDoc.candidateAcknowledgementRequired && (
                    <div style={{ marginTop: 32, display: 'flex', flexDirection: 'column', gap: 16 }}>
                      {isDocumentViewedByCandidate?.seen ? (
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          <div className="tag-ds status-tag_success" style={{ textTransform: 'uppercase' }}>
                            <span className="material-icons">visibility</span>
                            {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.seen')}
                          </div>
                          <br />
                          <span className="tag-ds  small">
                            {Utils.formatDate(isDocumentViewedByCandidate?.seenAtDate, 'en-US')}
                          </span>
                        </div>
                      ) : (
                        <div className="tag-ds status-tag_alert" style={{ textTransform: 'uppercase' }}>
                          {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.not_seen')}
                        </div>
                      )}
                      {isDocumentAcknowledged?.acknowledged ? (
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          <div className="tag-ds status-tag_success" style={{ textTransform: 'uppercase' }}>
                            <span className="material-icons">check_circle</span>
                            {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.acknowledged')}
                          </div>
                          <br />
                          <span className="tag-ds  small">
                            {Utils.formatDate(isDocumentAcknowledged?.acknowledgedAtDate, 'en-US')}
                          </span>
                        </div>
                      ) : (
                        <div className="tag-ds status-tag_alert" style={{ textTransform: 'uppercase' }}>
                          {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.not_acknowledged')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
