import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Sidebar, SidebarButton } from '@adeccoux/tag-ds';
import { UserRole } from '@models/user.model';
import { RootState } from '@store/rootReducer';

interface AdminSideNavigationProps {
  logo?: string;
}

const AdminSideNavigation: React.FC<AdminSideNavigationProps> = ({ logo }) => {
  const { t } = useTranslation('recruiter');
  const history = useHistory();
  const stateUser = useSelector((state: RootState) => state.authAdmin.user);
  const stateUserRole = useSelector((state: RootState) => state.authAdmin.userRole);
  const featureConfiguration = useSelector((state: RootState) => state.featureConfiguration);
  const userRole = stateUser?.role || stateUserRole;

  const links = [
    {
      icon: 'import_export',
      label: 'import',
      id: 'import',
      disabled: false,
      url: '/oo/recruiter/import-users',
      visibility: userRole !== UserRole.adeccoAdmin,
      ns: 'IMPORT',
      sequence: 10,
    },
    {
      icon: 'settings',
      label: 'configurations',
      disabled: false,
      id: 'configuration_import',
      url: '/oo/recruiter/import',
      visibility: userRole === UserRole.adeccoConfig,
      ns: 'CANDIDATE_DETAILS',
      sequence: 50,
    },
    {
      icon: 'key',
      label: 'accessManagement',
      disabled: false,
      id: 'access_management',
      url: '/oo/recruiter/permissions',
      visibility: userRole !== UserRole.adeccoStaff,
      ns: 'PERMISSIONS',
      sequence: 40,
    },
    {
      icon: 'alt_route',
      label: 'configurations',
      disabled:
        process.env.REACT_APP_ENV !== 'a-env' &&
        process.env.REACT_APP_ENV !== 'd-env' &&
        process.env.REACT_APP_ENV !== 't-env' &&
        process.env.REACT_APP_ENV !== 'u-env',
      id: 'self_service',
      url: '/oo/recruiter/self-service',
      visibility: userRole === UserRole.adeccoConfig,
      ns: 'SELF_SERVICE',
      sequence: 60,
    },
    {
      icon: 'list_alt',
      label: 'reportPageTitle',
      disabled: false,
      id: 'REPORT',
      url: '/oo/recruiter/report',
      visibility:
        (UserRole.adeccoAdmin === userRole ||
          UserRole.adeccoStaffAndAdmin === userRole ||
          UserRole.adeccoConfig === userRole) &&
        featureConfiguration.appSettings.reports,
      ns: 'REPORT',
      sequence: 70,
    },
    {
      icon: 'group',
      label: 'Candidate details',
      id: 'candidate_overview',
      disabled: false,
      dividerAfter: true,
      url: '/oo/recruiter',
      visibility: userRole !== UserRole.adeccoAdmin,
      ns: 'CANDIDATE_DETAILS',
      sequence: 20,
    },
    {
      icon: 'mail',
      label: 'MailOverview',
      // This item is enabled only when user is on candidate profile page
      disabled: !history.location.pathname.includes('/oo/recruiter/client-organization'),
      id: 'candidate_mails',
      url: `${
        history.location.pathname.includes('/mail-overview')
          ? history.location.pathname
          : history.location.pathname + '/mail-overview'
      }`,
      visibility: userRole !== UserRole.adeccoAdmin,
      ns: 'MAILS',
      sequence: 30,
    },
  ];

  return (
    <div className="tag-ds admin" style={{ display: 'flex', flexDirection: 'column' }}>
      {logo && (
        <img
          src={logo}
          alt="Dialog"
          height="16px"
          className="feature-dashboard__side-navigation"
          style={{ marginRight: 8 }}
        />
      )}
      <Sidebar
        collapsed={true}
        className="feature-dashboard__side-navigation"
        defaultItemSelected={links.find((x) => history.location.pathname?.includes(x.url))?.id}
      >
        {links
          .sort((a, b) => a.sequence - b.sequence)
          .map((x) => {
            return (
              x.visibility && (
                <SidebarButton
                  key={x.id}
                  icon={<span className="material-icons">{x.icon}</span>}
                  label={t(`recruiter:GENERAL.${x.ns?.toUpperCase()}.${x.label}`)} //ToDo: ToConfig
                  id={x.id}
                  disabled={x.disabled}
                  selected={history.location.pathname?.includes(x.url)}
                  onClick={() => {
                    history.push(x.url);
                  }}
                />
              )
            );
          })}
      </Sidebar>
    </div>
  );
};

export default AdminSideNavigation;
