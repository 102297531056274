import { FormikContextType } from 'formik';
import { ALL_EXTENDED_ROLES } from '@components/app/DocumentSteps/utils/constants';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class MapRolesToApplyRoles implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.allControls = formControls ?? [];
    this.formik = formik;
  }

  onChange = (event: any) => {
    const applyRolesControl = this.allControls.find((x) => x.name === 'APPLYING_ROLES');
    if (!applyRolesControl) return;

    const hasRole = (roles: string[]) => event.some((x: string) => roles.includes(x));
    const blueCollarRoles = hasRole(ALL_EXTENDED_ROLES.blueCollarRoles);
    const whiteCollarRoles = hasRole(ALL_EXTENDED_ROLES.whiteCollarRoles);

    const rolesToApply: string[] = [];
    if (blueCollarRoles) rolesToApply.push('blue collar');
    if (whiteCollarRoles) rolesToApply.push('white collar');

    setTimeout(() => {
      this.formik.setFieldValue(this.control.name, event);
      this.formik.setFieldValue(applyRolesControl.name, rolesToApply);
    });
  };
}
