import React, { useEffect, useMemo, useReducer, useState, VoidFunctionComponent } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownButton, DropdownMenu } from '@adeccoux/tag-ds';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { BoxedButton } from '@components/shared/BoxedButton/BoxedButton';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import VersionPicker from '@features/oneOnboardingAdmin/components/VersionPicker/VersionPicker';
import {
  useAggregatedDeletionReasonFilter,
  useAggregatedStatusFilters,
} from '@features/oneOnboardingAdmin/pages/AdminDashboard/adminDashboardHooks';
import { getLanguage, getTenantDefaultLanguage, storeTenant } from '@helpers/tenant.helper';
import { Utils } from '@helpers/utils';
import {
  useAllForDashboard,
  useFetchAvailableTenantsForRecruiter,
  useLocationsByRegions,
  useOnboardingFlowUsers,
} from '@hooks/apiHooks';
import { ExportConfiguration } from '@models/exportConfiguration.model';
import { QuickFilterModel } from '@models/quick-filter.model';
import { CheckUser, UserModel, UserRole } from '@models/user.model';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { authAppSlice } from '@store/slices/authApp.slice';
import { dashboardSlice } from '@store/slices/dashboard.slice';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { snackbarSlice } from '@store/slices/snackbar';

import AdminContainer from '../../../../components/admin/AdminContainer/AdminContainer';
import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { DropDownOptions, OOConfiguration } from '../../../oneOnboarding/interfaces';
import AdminAppointmentPicker from '../../components/AdminAppointmentPicker/AdminAppointmentPicker';
import AdminDashboardTable, {
  AdminTableActionType,
  AdminTableSelectionType,
  SelectionType,
} from '../../components/AdminDashboardTable/AdminDashboardTable';
import { useSorting } from '../../components/AdminDashboardTable/useSorting';
import { AdminReactiveSelect } from '../../components/AdminReactiveSelect/AdminReactiveSelect';
import AdminTableSearch from '../../components/AdminTableSearch/AdminTableSearch';
import { DashboardFilters } from '../../components/DashboardFilters/DashboardFilters';
import DashboardFiltersChipOverview from '../../components/DashboardFilters/DashboardFiltersChipOverview';
import { selectedValuesReducer } from '../../components/DashboardFilters/dashboardFiltersReducer';
import { ManualActionDialog } from '../../components/ManualActionDialog';
import { useAggregatedStepFilters } from './adminDashboardHooks';
import QuickFilter from './components/QuickFilter/QuickFilter';

const filterNames: { [key: string]: string } = {
  REGION1: 'region1',
  REGION2: 'region2',
  REGION3: 'region3',
  JOB_TYPE: 'JOB_TYPE',
  LOCATION: 'location',
  IS_EUCITIZEN: 'IS_EUCITIZEN',
  IS_STUDENT: 'IS_STUDENT',
  FAILED_MAILS: 'FAILED_MAILS',
  UNREAD_MESSAGES: 'UNREAD_MESSAGES',
};

const defaultStartDate = (offsetFromConfig?: number) => {
  const offset = offsetFromConfig ? -offsetFromConfig : -7;

  return moment.utc().startOf('day').add(offset, 'days').format('YYYY-MM-DD');
};

const defaultEndDate = moment.utc().format('YYYY-MM-DD');

export interface StepFilterModel {
  name: string;
  step?: string | string[];
  label: string;
  hasRejectedDocuments?: boolean | undefined;
  hasMandatoryDocumentsVerified?: boolean | undefined;
  inactivity?: number | undefined;
  userStatus?: string;
  withStuckCandidates?: boolean;
}
export interface StatusFilterModel {
  status: string;
  label: string;
}

export interface DeletionReasonFilterModel {
  status: string;
  label: string;
}
export interface FiltersVisibility {
  name: string;
  visible: boolean;
}

export const AdminDashboard: VoidFunctionComponent = () => {
  const { skippedCandidates } = useSelector((state: RootState) => state.dashboard);
  const { adminDashboardFilters, rejectReasons } = useSelector((store: RootState) => store.oneOnboarding);
  const [filterClientOrganizationId, setFilterClientOrganizationId] = useState<any>();
  const [filterJourneyId, setFilterJourneyId] = useState<any>();
  const { userId: recruiterId } = useSelector((state: RootState) => state.authAdmin);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [importing] = useState(false);
  const [controlsFetching, setControlsFetching] = useState(false);
  const [manualActionDialogOpen, setManualActionDialogOpen] = useState(false);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { data: dashboardData } = useAllForDashboard(tenantId);
  const { configurations: tenantConfigurations } = dashboardData ?? {};
  const [exports, setExports] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [quickFilter, setQuickFilter] = useState<any>();
  const [dashboardFilters, setDashboardFilters] = useState<Array<any>>([]);
  const [quickFilterItems, setQuickFilterItems] = useState<Array<QuickFilterModel> | null>(null);
  const [selectedConfigurations, setSelectedConfigurations] = useState<any[]>([]);
  const [tenantClientOrganizations, setTenantClientOrganizations] = useState<any[]>([]);
  const toBeDeletedReason = useMemo(
    () => adminDashboardFilters.deletedReason && adminDashboardFilters.deletedReason.length > 0,
    [adminDashboardFilters.deletedReason],
  );
  const [dashboardAccess, setDashboardAccess] = useState<boolean>(true);
  const [dateFormat, dateFormatSet] = useState<string>('dd/MM/yyyy');
  const [filterSidebarActive, setFilterSidebarActive] = useState(false);
  const toBeDeletedCandidates: any[] = [];
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [skipDeletionMail, setSkipDeletionMail] = useState<boolean>(false);
  const [deleteMultiUserModal, setDeleteMultiUserModal] = useState<boolean>(false);
  const [approveMultiUserModal, setApproveMultiUserModal] = useState<boolean>(false);
  const [rejectMultiUserModal, setRejectMultiUserModal] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>('');
  const [rejectReasonOther, setRejectReasonOther] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<CheckUser[]>([]);
  const [filterConfigFlow, setFilterConfigFlow] = useState<any>();
  const [selectedValues, dispatch] = useReducer(selectedValuesReducer, adminDashboardFilters);
  const [enabledTableButtons, setEnabledTableButtons] = useState<{
    rejectEnabled: boolean;
    approveEnabled: boolean;
  }>({
    rejectEnabled: true,
    approveEnabled: true,
  });
  const [selectionType, setSelectionType] = useState<AdminTableSelectionType>('');
  const [dropdownOptions, setDropDownOptions] = useState<DropDownOptions>({
    region1: [],
    AFFILIATION: [],
    JOB_TYPE: [],
    location: [],
  });

  const [filtersVisibility, setFiltersVisibility] = useState<FiltersVisibility[]>([]);

  const REJECT_OTHER = 'REJECT_OTHER';

  const allowedRoles = [UserRole.adeccoStaff, UserRole.adeccoConfig, UserRole.adeccoStaffAndAdmin];

  const { t } = useTranslation(['recruiter', 'control-items']);
  const aamBackendApi = useInjection(AamBackendApi);
  const storeDispatch = useDispatch();
  const history = useHistory();
  const { order, orderBy, setOrder, setOrderBy } = useSorting();
  const { data: locations = [] } = useLocationsByRegions(adminDashboardFilters.regions || []);

  const configuration = useMemo(
    () => (filterJourneyId ? tenantConfigurations?.find((config) => config.id === filterJourneyId) : undefined),
    [filterJourneyId, tenantConfigurations],
  );
  const { journeyId, ...otherFilters } = adminDashboardFilters;
  const exportCandidatesHandler = async (exportToBlobStorage: boolean, exportConfiguration?: ExportConfiguration) => {
    if (!users.length) return;

    await aamBackendApi.exportCandidates(
      {
        page: page + 1,
        selectedLanguage: i18n.language,
        sortDir: order.toUpperCase(),
        sort: orderBy,
        ...otherFilters,
        ...onboardingCreatedAtInterval,
        filter: quickFilter,
        tile: quickFilter,
        isOO: true,
        clientOrganizationId: adminDashboardFilters.clientOrganizationId,
        configurationId: journeyId,
        resultsPerPage: rowsPerPage,
        quickFilterItems: quickFilterItems,
      },
      exportToBlobStorage,
      exportConfiguration?.name,
    );
  };

  const downloadHiringListHandler = async () => {
    if (!users.length) return;
    await aamBackendApi.downloadHiringList({
      sortDir: order.toUpperCase(),
      sort: orderBy,
      ...adminDashboardFilters,
      ...onboardingCreatedAtInterval,
      isOO: true,
    });
  };

  const handleLanguageChange = (languages: any[]) => {
    const languageCodes = languages?.map((lang: any) => lang.code);
    if (!languageCodes?.includes(i18n.language)) {
      const defaultLanguage = languages?.find((lang: any) => lang.isDefault === true);
      const languageToSet = defaultLanguage?.code || getTenantDefaultLanguage(tenantId);
      i18n.changeLanguage(languageToSet);
      localStorage.setItem('i18nextLng', languageToSet);
    }
  };

  const dispatchData = ({ languages, rejectReasons }: any) => {
    storeDispatch(oneOnboardingSlice.actions.setLanguages(languages));
    storeDispatch(oneOnboardingSlice.actions.setRejectReasons(rejectReasons));
  };

  const processConfigurations = (configurations: any[]) => {
    const clientOrganizationMap: any = {};
    configurations?.forEach((itm: any) => {
      clientOrganizationMap[itm.clientOrganization.name] = {
        ...itm.clientOrganization,
        journeys: configurations?.filter((conf) => conf.clientOrganization.id === itm.clientOrganization.id),
      };
    });

    const tenantExports = configurations
      ?.filter((conf: any) => !!conf.exports)
      .reduce((acc: any[], itm: any) => [...acc, ...itm.exports.filter((x: any) => x.type !== 'approved')], []);
    if (tenantExports?.length > 0) {
      setExports(tenantExports);
    }

    setTenantClientOrganizations(() =>
      Object.values(clientOrganizationMap).sort((a: any, b: any) => a.name.localeCompare(b.name)),
    );

    setSelectedConfigurations(() =>
      configurations
        ?.map((x: any) => ({
          ...x,
          displayConfigurationName: x.clientOrganization?.displayName ?? x.clientOrganization?.name,
        }))
        .flat(),
    );
  };

  const { appointmentStartOffset, allowedBulkActions, searchOnboardingEndpointVersion } = useSelector(
    (state: RootState) => state.featureConfiguration.appSettings,
  );

  const { appointmentStartDate, appointmentEndDate } = useSelector((state: RootState) => state.dashboard);

  const [onboardingCreatedAtInterval, setOnboardingCreatedAtInterval] = useState({
    onboardingCreatedAtStartDate: appointmentStartDate
      ? appointmentStartDate
      : defaultStartDate(appointmentStartOffset),
    onboardingCreatedAtEndDate: appointmentEndDate ? appointmentEndDate : defaultEndDate,
  });

  // setting default appointment dates
  useEffect((): any => {
    if (!appointmentStartDate) {
      store.dispatch(dashboardSlice.actions.setAppointmentStartDate(defaultStartDate(appointmentStartOffset)));
    }

    if (!appointmentEndDate) {
      store.dispatch(dashboardSlice.actions.setAppointmentEndDate(defaultEndDate));
    }
  }, [appointmentStartDate, appointmentEndDate, appointmentStartOffset]);

  const { refetch: refetchUsers, isLoading: isLoadingUsers, data: responseUsersData } = useOnboardingFlowUsers({
    filters: adminDashboardFilters,
    page,
    order,
    orderBy,
    quickFilter,
    onboardingCreatedAtInterval,
    rowsPerPage,
    tenantId,
    quickFilterItems: (quickFilterItems?.map(({ number, ...rest }) => rest) || []) as Array<QuickFilterModel>,
    otherFilters,
    journeyId,
    searchOnboardingEndpointVersion,
  });

  const [users, total, quickFilters] = useMemo(() => {
    if (responseUsersData) {
      return [
        responseUsersData.results,
        responseUsersData.total,
        { quickFilter: responseUsersData.quickFilter, searchParams: responseUsersData.searchParams },
      ];
    }
    return [[], 0, { quickFilter: {}, searchParams: {} }];
  }, [responseUsersData]);
  const onSelectQuickFilter = (quickFilter: QuickFilterModel) => setQuickFilter(quickFilter);
  const onCloseQuickFilter = () => setQuickFilter(undefined);
  const deleteCandidate = async (
    users: any[],
    selectAll?: boolean,
    dashboardFilters?: { [key: string]: any },
    sendDeletionMail?: boolean,
  ) => {
    try {
      if (selectAll) {
        await aamBackendApi.deleteMultipleCandidatesSoft(
          tenantId,
          users,
          selectAll,
          dashboardFilters,
          sendDeletionMail,
        );
      }
      await aamBackendApi.deleteMultipleCandidatesSoft(tenantId, users, false, dashboardFilters, sendDeletionMail);
    } catch (err) {
      storeDispatch(
        snackbarSlice.actions.showError({
          message: 'Failed to delete user(s)',
        }),
      );
    } finally {
      setDeleteUserModal(false);
      storeDispatch(
        snackbarSlice.actions.showSuccess({
          message: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.candidatesDeleted'),
        }),
      );
      await refetchUsers();
    }
  };

  if (!dashboardAccess) {
    history.push('/oo/recruiter/unauthorized');
  }

  const filterControls = {
    isActive: filterSidebarActive,
    onClose: () => setFilterSidebarActive(false),
    onOpen: () => setFilterSidebarActive(true),
  };

  const checkTableUsers = async (users: CheckUser[], selectAll: boolean = false) => {
    try {
      setEnabledTableButtons({
        approveEnabled: false,
        rejectEnabled: false,
      });
      const result = await aamBackendApi.checkCandidate(
        filterClientOrganizationId,
        filterJourneyId,
        users,
        selectAll,
        adminDashboardFilters,
        searchOnboardingEndpointVersion,
      );
      setEnabledTableButtons(result);
    } catch (err) {
      window.alert(err);
    }
  };

  const handleSelectingUsers = (users: any[], selectionType: AdminTableSelectionType) => {
    const remap = (users: any): CheckUser[] =>
      users.map((x: any) => ({
        rejectedAt: x.rejectedAt,
        userId: x.userId,
        selectedConfiguration: x.selectedConfiguration,
        applicationId: x.applicationId,
        clientOrganizationId: x.clientOrganizationId,
        configurationId: x.configurationId,
      }));

    if (selectionType === SelectionType.totalEntries) {
      checkTableUsers(remap(users), true);
      return;
    }

    if (selectionType === SelectionType.pageEntries) {
      if (selectedUsers.length === users.length) {
        setSelectedUsers([]);
        checkTableUsers([]);
      } else {
        setSelectedUsers(remap(users));
        checkTableUsers(remap(users));
      }
      return;
    }

    const selectedUsersCopy = [...selectedUsers];

    users.forEach((x) => {
      const index = selectedUsersCopy.findIndex(
        (y) =>
          y.applicationId === x.applicationId &&
          y.clientOrganizationId === x.clientOrganizationId &&
          y.userId === x.userId,
      );

      if (index > -1) {
        selectedUsersCopy.splice(index, 1);
      } else {
        selectedUsersCopy.push(x);
      }
    });

    checkTableUsers(remap(selectedUsersCopy));
    setSelectedUsers(selectedUsersCopy);
  };

  const handleTableActions = (actionType: AdminTableActionType) => {
    const actionMap: { [key: string]: () => void } = {
      [AdminTableActionType.reject]: async () => {
        setRejectMultiUserModal(true);
      },
      [AdminTableActionType.delete]: async () => {
        setDeleteMultiUserModal(true);
      },
      [AdminTableActionType.approve]: async () => {
        setApproveMultiUserModal(true);
      },
    };

    if (!actionMap[actionType]) {
      throw new Error(`${actionType} functionality does not exist`);
    }

    return actionMap[actionType]();
  };

  const isApproveButtonVisible = allowedBulkActions
    ? !!allowedBulkActions.find((item) => item.name === 'APPROVE')
    : false;
  const isRejectButtonVisible = allowedBulkActions ? !!allowedBulkActions.find((item) => item.name === 'REJECT') : true;
  const isDeleteButtonVisible = allowedBulkActions ? !!allowedBulkActions.find((item) => item.name === 'DELETE') : true;

  useEffect(() => {
    storeDispatch(
      dashboardSlice.actions.setAppointmentStartDate(onboardingCreatedAtInterval.onboardingCreatedAtStartDate),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingCreatedAtInterval.onboardingCreatedAtStartDate]);

  useEffect(() => {
    storeDispatch(dashboardSlice.actions.setAppointmentEndDate(onboardingCreatedAtInterval.onboardingCreatedAtEndDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingCreatedAtInterval.onboardingCreatedAtEndDate]);

  const reInit = async (newTenantId: number): Promise<void> => {
    store.dispatch(authAppSlice.actions.clear());
    localStorage.removeItem('clientOrganizationId');
    localStorage.removeItem('selectedConfigurationId');
    storeTenant(newTenantId);
    if (i18n?.services?.languageUtils) {
      const lang = getLanguage(newTenantId);
      await i18n.changeLanguage(lang);
    }
    window.location.href = '/oo/recruiter';
  };

  const { data: availableTenantIds } = useFetchAvailableTenantsForRecruiter(recruiterId);
  useEffect((): any => {
    if (availableTenantIds?.length === 1) {
      if (availableTenantIds[0] !== tenantId) {
        reInit(availableTenantIds[0]);
      }
    }
  }, [availableTenantIds, tenantId]);
  useEffect(() => {
    if (dashboardData) {
      const { languages, configurations, rejectReasons } = dashboardData;
      handleLanguageChange(languages);
      dispatchData({ languages, configurations, rejectReasons });
      processConfigurations(configurations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);
  useEffect((): any => {
    let isMounted = true;
    const call = async () => {
      if (recruiterId) {
        const user = (await aamBackendApi.fetchUser(tenantId, recruiterId)) as UserModel;
        if (isMounted) {
          if (!user) {
            setDashboardAccess(false);
            return;
          }
          if (user.role === UserRole.adeccoAdmin) {
            history.push('/oo/recruiter/permissions');
          }
          setDashboardAccess(allowedRoles.includes(user.role));
        }
      }
    };
    call().catch((err) => window.alert(err));
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, recruiterId, aamBackendApi]);

  useEffect(() => {
    if (locations?.length) {
      setDropDownOptions((state: any) => ({
        ...state,
        location: [...locations],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if (tenantConfigurations?.length === 1 && !filterClientOrganizationId) {
      setFilterClientOrganizationId(() => tenantConfigurations[0].clientOrganizationId);
    }

    // TODO - this condition is wrong as filterJourneyId might belong to a different clientOrg entirely
    if (!!filterClientOrganizationId && !!filterJourneyId) {
      (async () => {
        const selectedConfigurationData = await aamBackendApi.fetchSelectedConfiguration(
          filterClientOrganizationId,
          filterJourneyId as string,
        );
        setFilterConfigFlow(selectedConfigurationData?.flow);
      })();

      const filterConfig = tenantConfigurations?.find(
        (itm: any) =>
          itm.clientOrganisationId === filterClientOrganizationId && itm.configurationId === filterJourneyId,
      );
      if (filterConfig) {
        if (filterConfigFlow) {
          storeDispatch(dashboardSlice.actions.setRecruiterElements((filterConfigFlow as any)?.elements));
        }

        const format = filterConfig.otherConfiguration?.find((d: any) => d.name === 'displayDateFormat')?.value;
        if (format) {
          dateFormatSet(() => format);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminDashboardFilters, tenantConfigurations, filterClientOrganizationId, filterJourneyId]);

  const stepFiltersItems = useAggregatedStepFilters(tenantConfigurations, filterClientOrganizationId, filterJourneyId);

  const statusFiltersItems = useAggregatedStatusFilters(
    tenantConfigurations,
    filterClientOrganizationId,
    filterJourneyId,
  );

  const deletionReasonItems = useAggregatedDeletionReasonFilter(
    tenantConfigurations,
    filterClientOrganizationId,
    filterJourneyId,
  );

  useEffect((): any => {
    const getDropDownOptions = async () => {
      const options: any = {
        applicationId: '',
        journeyId: '',
        region1: [],
        AFFILIATION: [],
        JOB_TYPE: [],
        location: [],
      };
      setControlsFetching(() => true);

      const getAggregatedCustomFilters = (clientOrganizationId: string) => {
        const allCustomFilters = !clientOrganizationId
          ? tenantConfigurations?.flatMap((journey: any) => journey.customFilters ?? [])
          : tenantConfigurations
              ?.filter((client) => client.clientOrganizationId === clientOrganizationId)
              .flatMap((journey) => journey.customFilters ?? []);
        return [...new Map(allCustomFilters?.map((filter: any) => [filter.name, filter])).values()];
      };
      let configuration: OOConfiguration | undefined;
      if (filterClientOrganizationId) {
        configuration = filterJourneyId
          ? tenantConfigurations?.find(
              (config) => config.id === filterJourneyId && config.clientOrganizationId === filterClientOrganizationId,
            )
          : undefined;
      } else {
        configuration = filterJourneyId
          ? tenantConfigurations?.find((config) => config.id === filterJourneyId)
          : undefined;
      }
      const customFilters = configuration
        ? configuration?.customFilters ?? []
        : getAggregatedCustomFilters(filterClientOrganizationId);
      const filtersArray = customFilters as [];
      const selectorNames = filtersArray
        .filter((fil: { name: string; type: string }) => fil.type === 'selector' || fil.type === 'boolean')
        .map((filter: { name: string; type: string }) => filterNames[filter.name]);

      let controlItemsMap: any = {};
      if (configuration && configuration.clientOrganizationId && selectorNames.length > 0) {
        controlItemsMap = await aamBackendApi.getControlsItems(
          configuration.clientOrganizationId,
          configuration.id,
          selectorNames,
        );
      }
      selectorNames.forEach((selectorName: string) => {
        if (configuration) {
          if (configuration.customFilters) {
            const configurationSelectorFilter = configuration?.customFilters.find((filter: any) =>
              filter.name.includes(selectorName),
            );
            if (configurationSelectorFilter && filterClientOrganizationId) {
              options[selectorName] = controlItemsMap[configurationSelectorFilter.controlHeaderName];
            }
          } else {
            options[selectorName] = controlItemsMap[selectorName];
          }
        }
      });
      setFiltersVisibility(configuration?.dashboardFiltersVisibility ?? []);
      setDashboardFilters(filtersArray);
      setControlsFetching(false);
      setDropDownOptions(options as DropDownOptions);
    };
    getDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterClientOrganizationId, aamBackendApi, configuration, t, tenantConfigurations, filterJourneyId]);

  useEffect(() => {
    if (filterClientOrganizationId && filterJourneyId) {
      aamBackendApi
        .fetchSelectedMail(filterClientOrganizationId, filterJourneyId)
        .then((data) => storeDispatch(oneOnboardingSlice.actions.setMails(data)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterClientOrganizationId, adminDashboardFilters]);

  const quickFilterValues = useMemo(() => {
    return quickFilters?.quickFilter || {};
  }, [quickFilters?.quickFilter]);

  useEffect(() => {
    const getAggregatedTiles = () => {
      const allTiles = tenantConfigurations?.flatMap((journey) => journey?.tiles ?? []);
      return [...new Map(allTiles?.map((tile) => [tile.name, tile])).values()];
    };

    const tiles = configuration ? configuration?.tiles ?? [] : getAggregatedTiles();
    const quickFiltersTemp: QuickFilterModel[] = [];
    for (const item of tiles) {
      const quickFilterNumber = (quickFilterValues[item.name] ? quickFilterValues[item.name] : 0) as number;
      quickFiltersTemp.push({
        key: item.label,
        number: quickFilterNumber,
        title: t(`control-items:GENERAL.${item.label}`, item.options),
        name: item.name,
        description: item.description,
        step: item.step,
        hasRejectedDocuments: item.hasRejectedDocuments,
        hasMandatoryDocumentsVerified: item.hasMandatoryDocumentsVerified,
        inactivity: item.inactivity,
        userStatus: item.userStatus,
        options: item.options,
        withStuckCandidates: item.withStuckCandidates,
        clientOrganizationId: item.clientOrganizationId,
        configurationId: item.configurationId,
        userStatusIsNot: item.userStatusIsNot,
      });
    }
    setQuickFilterItems(() => quickFiltersTemp);
  }, [configuration, quickFilterValues, quickFilters, t, tenantConfigurations]);

  if (!selectedConfigurations[0]) {
    return <Loader />;
  }
  return (
    <div className="admin">
      <PageWrapper
        featureName="dashboard"
        pageName="dashboard"
        sideMenu
        headerConfig={{ pageName: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.candidates'), languagePicker: true }}
      >
        <DashboardFilters
          selectedValues={selectedValues}
          dispatch={dispatch}
          filters={dashboardFilters}
          dropdownOptions={dropdownOptions}
          stepFiltersOptions={stepFiltersItems}
          onboardingStatusOptions={statusFiltersItems}
          disabledDropDowns={{
            LOCATION: !adminDashboardFilters?.regions?.length,
          }}
          selectedConfiguration={configuration}
          allClientOrganizations={tenantClientOrganizations}
          allJourneys={selectedConfigurations?.map((x) => ({
            recruiterName: x.recruiterName,
            id: JSON.stringify({
              id: x.id,
              clientOrganizationId: x.clientOrganizationId,
            }),
          }))}
          onClientOrganizationChange={(clientOrganizationId: string) =>
            setFilterClientOrganizationId(clientOrganizationId)
          }
          onJourneyChange={(journeyId: string) => {
            setFilterClientOrganizationId(JSON.parse(journeyId).clientOrganizationId);
            setFilterJourneyId(JSON.parse(journeyId).id);
          }}
          controlsFetching={controlsFetching}
          filterControls={filterControls}
          filtersVisibility={filtersVisibility}
          resetPageNumber={() => {
            setPage(0);
          }}
          deletionReasonItems={deletionReasonItems}
        />

        <AdminContainer>
          {!!quickFilterItems && !!quickFilterItems.length && (
            <QuickFilter
              items={quickFilterItems}
              quickFilter={quickFilter}
              onSelectQuickFilter={onSelectQuickFilter}
              resetPageNumber={() => {
                setPage(0);
              }}
              onCloseQuickFilter={onCloseQuickFilter}
            />
          )}

          <VersionPicker />

          <Grid container alignItems="baseline" justifyContent="space-between">
            <Grid item>
              <Box display="flex" alignItems="baseline">
                <Grid item style={{ marginRight: 16, alignSelf: 'flex-start' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('recruiter:totalRecords')}</label>
                    <div style={{ marginTop: '8px' }}>{total}</div>
                  </div>
                </Grid>

                <AdminAppointmentPicker
                  dateFormat={dateFormat}
                  onboardingCreatedAtInterval={onboardingCreatedAtInterval}
                  setOnboardingCreatedAtInterval={setOnboardingCreatedAtInterval}
                />
                <AdminTableSearch handleDispatch={dispatch} />
              </Box>
            </Grid>
            <Grid item>
              <div className="tag-ds">
                <Box display="flex">
                  <button className="button-secondary" style={{ marginRight: 32 }} onClick={filterControls.onOpen}>
                    {t('recruiter:GENERAL.CANDIDATES_OVERVIEW.filters')}
                    <span className="material-icons right">filter_list</span>
                  </button>
                  <Dropdown>
                    <DropdownButton className="button button-secondary">
                      {t('recruiter:GENERAL.CANDIDATES_OVERVIEW.export')}
                      <KeyboardArrowDownIcon />
                    </DropdownButton>
                    <DropdownMenu className="admin-dropdown-menu">
                      <li className="dropdown-item admin-export-options" onClick={() => exportCandidatesHandler(false)}>
                        {t('recruiter:GENERAL.CANDIDATES_OVERVIEW.exportCandidates')}
                      </li>
                      <li className="dropdown-item admin-export-options" onClick={() => exportCandidatesHandler(true)}>
                        {t('GENERAL.CANDIDATES_OVERVIEW.exportToCloudStorage')}
                      </li>
                      {!!adminDashboardFilters.startDate && (
                        <li className="dropdown-item admin-export-options" onClick={() => downloadHiringListHandler()}>
                          {t('GENERAL.CANDIDATES_OVERVIEW.downloadHiringList')}
                        </li>
                      )}
                      {exports.map((exportConfiguration, index) => {
                        return (
                          <li
                            className="dropdown-item admin-export-options"
                            onClick={() => exportCandidatesHandler(false, exportConfiguration)}
                            key={'ce-' + index}
                          >
                            {t(exportConfiguration.label)}
                          </li>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </Box>
              </div>
            </Grid>
          </Grid>
          <DashboardFiltersChipOverview dispatch={dispatch} resetPageNumber={() => setPage(0)} />
        </AdminContainer>
        {controlsFetching ? null : (
          <AdminContainer>
            {isLoading || isLoadingUsers || importing ? (
              <Box style={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  style={{
                    textAlign: 'right',
                    marginLeft: 'auto',
                  }}
                >
                  {toBeDeletedReason && toBeDeletedCandidates.length > 0 && (
                    <>
                      <BoxedButton
                        size="small"
                        variant="outlined"
                        disabled={!toBeDeletedCandidates.length}
                        color="primary"
                        text={
                          toBeDeletedCandidates.length
                            ? t('GENERAL.CANDIDATE_DELETION.delete') + ' ' + toBeDeletedCandidates.length
                            : t('GENERAL.CANDIDATE_DELETION.delete')
                        }
                        component="span"
                        onClick={() => setDeleteUserModal(true)}
                      />
                      &nbsp;
                    </>
                  )}
                  &nbsp;
                </Box>

                <AdminDashboardTable
                  data={users}
                  allOrganizations={tenantClientOrganizations}
                  allJournies={selectedConfigurations}
                  dateFormat={dateFormat}
                  pagination={{
                    page,
                    setPage,
                    total,
                    rowsPerPage,
                    setRowsPerPage,
                  }}
                  selectedUsers={selectedUsers}
                  handleSelectingUsers={handleSelectingUsers}
                  enabledTableButtons={enabledTableButtons}
                  handleTableActions={handleTableActions}
                  selection={{
                    selectionType,
                    setSelectionType,
                  }}
                  isApproveButtonVisible={isApproveButtonVisible}
                  isRejectButtonVisible={isRejectButtonVisible}
                  isDeleteButtonVisible={isDeleteButtonVisible}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                />
              </>
            )}
          </AdminContainer>
        )}
        <ManualActionDialog
          open={manualActionDialogOpen}
          users={skippedCandidates}
          onSelect={(item) => {
            if (item) {
              storeDispatch(dashboardSlice.actions.selectUser(item));
            } else {
              setManualActionDialogOpen(false);
            }
          }}
        />
        <Dialog
          className="candidate-profile-delete-modal"
          open={deleteUserModal}
          onClose={() => setDeleteUserModal(false)}
        >
          <DialogTitle>
            <NotificationImportantIcon />
            <Typography variant="h5" component="span">
              {t('important')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography variant="h4">{t('deleteCandidateUserMessage')}</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <BoxedButton size="small" onClick={() => setDeleteUserModal(false)} variant="outlined" text={t('no')} />
            <BoxedButton
              size="small"
              onClick={() => deleteCandidate(toBeDeletedCandidates)}
              autoFocus
              text={t('yes')}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          className="multi-candidate-delete-modal"
          open={deleteMultiUserModal}
          onClose={() => setDeleteMultiUserModal(false)}
        >
          <DialogTitle>
            <Typography variant="h3" component="span">
              {t('recruiter:GENERAL.MULTI_CANDIDATE_DELETION_MODAL.title')}
            </Typography>
            <IconButton aria-label="close" onClick={() => setDeleteMultiUserModal(false)} className="closer">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                {Utils.sprintf(t('recruiter:GENERAL.MULTI_CANDIDATE_DELETION_MODAL.content'), selectedUsers.length)}
              </Typography>
              <Box style={{ paddingTop: '12px' }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="checkBox"
                        onChange={() => setSkipDeletionMail(!skipDeletionMail)}
                        checked={skipDeletionMail}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'start',
                          justifyContent: 'left',
                        }}
                      />
                    }
                    label={t('recruiter:GENERAL.CANDIDATE_DELETION.skipDeletionMail')}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}
                  />
                </FormGroup>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <BoxedButton
              size="small"
              onClick={() => setDeleteMultiUserModal(false)}
              variant="outlined"
              text={t('recruiter:GENERAL.MULTI_CANDIDATE_DELETION_MODAL.actionCancel')}
            />
            <BoxedButton
              size="small"
              onClick={() => {
                try {
                  setLoading(true);
                  const sendDeletionMail = !skipDeletionMail;
                  deleteCandidate(
                    selectedUsers,
                    selectionType === SelectionType.totalEntries,
                    adminDashboardFilters,
                    sendDeletionMail,
                  );
                  setSelectedUsers([]);
                } catch (err) {
                  storeDispatch(
                    snackbarSlice.actions.showError({
                      message: t('recruiter:GENERAL.MULTI_CANDIDATE_DELETION_MODAL.failedMessage'),
                    }),
                  );
                }
                setLoading(false);
                setDeleteMultiUserModal(false);
              }}
              autoFocus
              text={t('recruiter:GENERAL.MULTI_CANDIDATE_DELETION_MODAL.actionConfirm')}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          className="multi-candidate-delete-modal"
          open={approveMultiUserModal}
          onClose={() => setApproveMultiUserModal(false)}
        >
          <DialogTitle>
            <Typography variant="h3" component="span" style={{ marginRight: '30px' }}>
              {t('recruiter:GENERAL.MULTI_CANDIDATE_APPROVAL_MODAL.title')}
            </Typography>
            <IconButton aria-label="close" onClick={() => setApproveMultiUserModal(false)} className="closer">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                {Utils.sprintf(t('recruiter:GENERAL.MULTI_CANDIDATE_APPROVAL_MODAL.content'), selectedUsers.length)}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <BoxedButton
              size="small"
              onClick={() => setApproveMultiUserModal(false)}
              variant="outlined"
              text={t('recruiter:GENERAL.MULTI_CANDIDATE_APPROVAL_MODAL.actionCancel')}
            />
            <BoxedButton
              size="small"
              onClick={async () => {
                try {
                  const selectAll = selectionType === SelectionType.totalEntries;
                  setLoading(true);
                  await aamBackendApi.ooAdminCompleteStepForUserBulk(filterClientOrganizationId, filterJourneyId, {
                    selectedUsers,
                    selectAll,
                    adminDashboardFilters,
                    stepName: 'APPROVE',
                  });
                  await refetchUsers();
                  setSelectedUsers([]);
                } catch (err) {
                  storeDispatch(
                    snackbarSlice.actions.showError({
                      message: t('recruiter:GENERAL.MULTI_CANDIDATE_APPROVAL_MODAL.failedMessage'),
                    }),
                  );
                }
                setLoading(false);
                setApproveMultiUserModal(false);
              }}
              autoFocus
              text={t('recruiter:GENERAL.MULTI_CANDIDATE_APPROVAL_MODAL.actionConfirm')}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          className="multi-candidate-delete-modal"
          open={rejectMultiUserModal}
          onClose={() => {
            setRejectMultiUserModal(false);
          }}
        >
          <DialogTitle>
            <Typography variant="h3" component="span">
              {t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.title')}
            </Typography>
            <IconButton aria-label="close" onClick={() => setRejectMultiUserModal(false)} className="closer">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                {Utils.sprintf(t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.text1'), selectedUsers.length)}
              </Typography>
              <Typography>{t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.text2')}</Typography>
              <Box className="dialog-form">
                <AdminReactiveSelect
                  value={rejectReason}
                  label={t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.select.label')}
                  name="reject-reason"
                  placeholder={t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.select.placeholder')}
                  onChange={(e) => setRejectReason((e as any).target.value)}
                  options={[
                    ...(rejectReasons || []).map((rejectReasonOption) => ({
                      name: t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.select.options.' + rejectReasonOption),
                      value: rejectReasonOption,
                    })),
                    {
                      name: t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.select.options.other'),
                      value: REJECT_OTHER,
                    },
                  ]}
                />
                {rejectReason === REJECT_OTHER && (
                  <TextField
                    id="outlined-textarea"
                    onChange={(e) => setRejectReasonOther(e.target.value)}
                    placeholder={t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.other.placeholder')}
                    multiline
                  />
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <BoxedButton
              size="small"
              onClick={() => {
                setRejectMultiUserModal(false);
              }}
              variant="outlined"
              text={t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.action.cancel')}
            />
            <BoxedButton
              disabled={
                ['', undefined].includes(rejectReason) ||
                (rejectReason === REJECT_OTHER && rejectReasonOther?.length < 3) ||
                selectedUsers.length === 0
              }
              size="small"
              onClick={async () => {
                setLoading(true);
                try {
                  await aamBackendApi.ooRejectUserBulk(
                    filterClientOrganizationId,
                    filterJourneyId,
                    selectedUsers,
                    selectionType === SelectionType.totalEntries,
                    adminDashboardFilters,
                    rejectReason !== REJECT_OTHER ? rejectReason : rejectReasonOther,
                  );
                  await refetchUsers();
                  setSelectedUsers([]);
                } catch (err) {
                  storeDispatch(
                    snackbarSlice.actions.showError({
                      message: 'Failed to reject user(s)',
                    }),
                  );
                }
                setLoading(false);
                setRejectReason('');
                setRejectReasonOther('');
                setRejectMultiUserModal(false);
              }}
              autoFocus
              text={t('recruiter:GENERAL.MULTI_CANDIDATE_REJECTION_MODAL.action.confirm')}
            />
          </DialogActions>
        </Dialog>
      </PageWrapper>
    </div>
  );
};
