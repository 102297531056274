export const ALL_EXTENDED_ROLES = {
  whiteCollarRoles: [
    'Accounting',
    'Accounts Payable/Receivable',
    'Administration',
    'Call Center',
    'Customer Service',
    'Data Processing',
    'Executive Assistant',
    'Executive/Department Head',
    'Government',
    'Human Resources',
    'IT',
    'Marketing/Communications',
    'Medical/Healthcare worker',
    'Payroll',
    'Personal Assistant',
    'Project Management',
    'Reception',
    'Retail',
    'Sales/Business Development',
    'Warehousing/Logistics Admin/Manager',
    'WHS/Workers Compensation',
  ],
  blueCollarRoles: [
    'Assembly Worker',
    'Boiler Maker',
    'Carpenter',
    'Cleaners',
    'Delivery Driver',
    'Electrician',
    'Engineer',
    'Fitter and Tuner',
    'Forklift Operator',
    'Freight Handler',
    'Grocery and Stores',
    'High Reach Forklift Operator',
    'Labourer',
    'Licenced Mobile Plant Equipment Operator',
    'Machine Operator',
    'Maintenance Technicians',
    'Mechanic',
    'Order Picker Driver (LO)',
    'Pick/Pack',
    'Plumber',
    'Production Operator',
    'Production/General Line worker',
    'Trades Assistant',
    'Truck Driver',
    'Warehouse Assistant',
  ],
};
