import { injectable } from 'inversify';
import { v4 as uuid } from 'uuid';

@injectable()
export class Utils {
  public static randomNumber(min: number = 0, max: number = 4294967295): number {
    const fallbackToMathRandom = () => {
      Utils.consoleLog('Falling back to Math.Random. It is not an issue, since we dont use it for cryptography');
      return Math.random() * max;
    };

    const crypto = window.crypto || window.msCrypto;
    if (!crypto) {
      return fallbackToMathRandom();
    }

    const array = new Uint32Array(1);
    const numbers = crypto.getRandomValues(array);

    if (!numbers.length) {
      return fallbackToMathRandom();
    }

    const number = numbers[0] / (0xffffffff + 1);

    return Math.floor(number * (max - min + 1)) + min;
  }

  public randomString(
    length = 10,
    possible = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  ): string {
    const s = new Array(length);
    for (let i = 0; i < length; ++i) {
      s[i] = possible.charAt(Utils.randomNumber(0, possible.length));
    }
    return s.join('');
  }

  public generateClientTrace(): string {
    return `CT-${this.randomString(6)}`;
  }

  public generateRequestTrace(): string {
    return `RT-${this.randomString(8)}`;
  }

  public uuid(): string {
    return uuid();
  }

  public getQueryString(name: string) {
    name = name.replace('[', '\\[').replace(']', '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace('+', ' '));
  }

  public static encodeBase64(message: string): string {
    return btoa(message);
  }

  public static decodeBase64(message: string): string {
    return atob(message);
  }

  public extractHashAndSearch = (hash: string, search: string): { [key: string]: any } => {
    const reduceFn = (carry: any, hashPart: string) => {
      const hParts = hashPart.split('=');
      if (hParts.length === 2) {
        carry[hParts[0]] = decodeURIComponent(hParts[1].replace('+', ' '));
      } else {
        carry[hParts[0]] = '';
      }
      return carry;
    };
    const result = hash.substr(1).split('&').reduce(reduceFn, {});
    return search.substr(1).split('&').reduce(reduceFn, result);
  };

  public static sprintf = (...args: any[]) => {
    const replacer = '%s';
    const formatted = args.shift();
    if (formatted.indexOf(replacer) === -1) {
      return formatted;
    }
    let replaced = formatted;
    args.forEach((arg) => {
      replaced = replaced.replace(replacer, arg);
    });
    return replaced;
  };

  public static consoleLog = (message?: any, ...optionalParams: any[]): void => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  };

  public static consoleError = (message?: any, ...optionalParams: any[]): void => {
    if (process.env.REACT_LOG_LEVEL === 'debug') {
      // eslint-disable-next-line no-console
      console.error(message, ...optionalParams);
    }
  };
  public static formatDate = (dateString: string | Date, local: 'en-US') =>
    new Date(dateString).toLocaleDateString(local, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}
